import cn from 'classnames';
import Link from 'next/link';
import type { FC } from 'react';
import React from 'react';

import s from './ALink.module.scss';

export interface ALinkTypes extends React.HTMLAttributes<HTMLAnchorElement> {
  href?: string;
  target?: '_blank' | '_self';
  onClick?: () => void;
}

const ALink: FC<ALinkTypes> = ({
  href,
  children,
  className,
  target = '_self',
  onClick,
  ...restProps
}) => {
  if (!href) {
    return (
      <span
        className={cn([s.link, className])}
        onClick={onClick}
        {...restProps}
      >
        {children}
      </span>
    );
  }

  return (
    <Link
      href={href}
      className={cn([s.link, className])}
      target={target}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </Link>
  );
};

export default ALink;
