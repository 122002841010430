/*
  eslint-disable
    @typescript-eslint/no-explicit-any,
    @typescript-eslint/no-unsafe-member-access
*/
import type { SchemaOf } from 'yup';

export async function simpleValidation<ObjectShape>(
  object: ObjectShape,
  schema: SchemaOf<ObjectShape>,
): Promise<{ inner: { path: string; message: string }[] } | undefined> {
  try {
    await schema.validate(object, { abortEarly: false });
  } catch (e: any) {
    return e;
  }
}

export async function oneFieldValidation<ObjectShape>(
  field: keyof ObjectShape,
  object: ObjectShape,
  schema: SchemaOf<ObjectShape>,
): Promise<{ inner: { path: string; message: string }[] } | undefined> {
  try {
    if (typeof field === 'string') {
      await schema.validateAt(field, object, { abortEarly: false });
    }
  } catch (e: any) {
    return e;
  }
}
