import cn from 'classnames';
import React from 'react';

import s from './typography.module.scss';

export interface ITypographyProps extends React.HTMLAttributes<HTMLElement> {
  'data-test-id'?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  Tag?: any;
  href?: string;
}

const Typography: React.FC<ITypographyProps> = props => {
  const { Tag = 'div', className, ...restProps } = props;

  return <Tag className={cn(s.typography, className)} {...restProps} />;
};

Typography.displayName = '_TypographyCommon';

export const H1: React.FC<ITypographyProps> = ({ className, ...restProps }) => {
  return (
    <Typography Tag="h1" className={cn(s.h, s.h1, className)} {...restProps} />
  );
};

H1.displayName = 'Typography/Headline 1 (H1)';

export const H2: React.FC<ITypographyProps> = ({ className, ...restProps }) => {
  return (
    <Typography Tag="h2" className={cn(s.h, s.h2, className)} {...restProps} />
  );
};

H2.displayName = 'Typography/Headline 2 (H2)';

export const H3: React.FC<ITypographyProps> = ({ className, ...restProps }) => {
  return (
    <Typography Tag="h3" className={cn(s.h, s.h3, className)} {...restProps} />
  );
};

H3.displayName = 'Typography/Headline 3 (H3)';

export const H4: React.FC<ITypographyProps> = ({ className, ...restProps }) => {
  return (
    <Typography Tag="h4" className={cn(s.h, s.h4, className)} {...restProps} />
  );
};

H4.displayName = 'Typography/Headline 4 (H4)';

export const Title1: React.FC<ITypographyProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Typography
      Tag="h3"
      className={cn(s.title, s.title1, className)}
      {...restProps}
    />
  );
};

Title1.displayName = 'Typography/Title1 (Title1)';

export const Title2: React.FC<ITypographyProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Typography
      Tag="span"
      className={cn(s.title, s.title2, className)}
      {...restProps}
    />
  );
};

Title2.displayName = 'Typography/Title2 (Title2)';

export const Title3: React.FC<ITypographyProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Typography
      Tag="span"
      className={cn(s.title, s.title3, className)}
      {...restProps}
    />
  );
};

Title3.displayName = 'Typography/Title3 (Title3)';

export const Body1: React.FC<ITypographyProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Typography
      Tag="span"
      className={cn(s.body, s.body1, className)}
      {...restProps}
    />
  );
};

Body1.displayName = 'Typography/Body1 (Body1)';

export const Body2: React.FC<ITypographyProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Typography
      Tag="span"
      className={cn(s.body, s.body2, className)}
      {...restProps}
    />
  );
};

Body2.displayName = 'Typography/Body2 (Body2)';

export const Body3: React.FC<ITypographyProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Typography
      Tag="span"
      className={cn(s.body, s.body3, className)}
      {...restProps}
    />
  );
};

Body3.displayName = 'Typography/Body3 (Body3)';

export const Body4: React.FC<ITypographyProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Typography
      Tag="span"
      className={cn(s.body, s.body4, className)}
      {...restProps}
    />
  );
};

Body4.displayName = 'Typography/Body4 (Body4)';

export const Body5: React.FC<ITypographyProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Typography
      Tag="span"
      className={cn(s.body, s.body5, className)}
      {...restProps}
    />
  );
};

Body5.displayName = 'Typography/Body5 (Body5)';

export const BodyCode: React.FC<ITypographyProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Typography
      Tag="span"
      className={cn(s.body, s.bodyCode, className)}
      {...restProps}
    />
  );
};

BodyCode.displayName = 'Typography/BodyCode (BodyCode)';

export const Caption: React.FC<ITypographyProps> = ({
  className,
  ...restProps
}) => (
  <Typography Tag="span" className={cn(s.caption, className)} {...restProps} />
);

Caption.displayName = 'Typography/Caption (Caption)';

export const Caption2: React.FC<ITypographyProps> = ({
  className,
  ...restProps
}) => (
  <Typography Tag="span" className={cn(s.caption2, className)} {...restProps} />
);

Caption2.displayName = 'Typography/Caption2 (Caption2)';

export const Caption3: React.FC<ITypographyProps> = ({
  className,
  ...restProps
}) => (
  <Typography Tag="span" className={cn(s.caption3, className)} {...restProps} />
);

Caption3.displayName = 'Typography/Caption3 (Caption3)';

export const CaptionCode: React.FC<ITypographyProps> = ({
  className,
  ...restProps
}) => (
  <Typography
    Tag="span"
    className={cn(s.captionCode, className)}
    {...restProps}
  />
);

CaptionCode.displayName = 'Typography/CaptionCode (CaptionCode)';
