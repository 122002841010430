import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import { ColorsEnum } from 'app.types';
import useAnalytics from 'hooks/useAnalytics';
import useValidation from 'hooks/useValidation';
import { useRouter } from 'next/router';
import type { FC, FormEvent } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import type { SchemaOf } from 'yup';

import { gtmEvents } from 'constants/gtmEvent';

import ALink from 'components/basic/ALink';
import Button from 'components/basic/Button';
import Cross from 'components/basic/Cross';
import InputText from 'components/basic/InputText';
import { Body4, Caption, H4 } from 'components/basic/Typography';
import SelectBasicStylized from 'components/complex/SelectBasicStylized';
import s from 'components/complex/forms/ApplicationForm/ApplicationForm.module.scss';
import SendStatusProviderForm from 'components/complex/forms/ApplicationForm/components/SendStatusApplicationForm';
import {
  recaptchaApiKey,
  values,
} from 'components/complex/forms/ApplicationForm/constants';
import type {
  ApplicationFormTypes,
  ApplicationFormYupTypes,
  SelectOptionType,
} from 'components/complex/forms/ApplicationForm/types';

const ApplicationForm: FC<ApplicationFormTypes> = ({ setShowForm, logo }) => {
  const { sendFormAnalytics } = useAnalytics();
  const [selectValue, setSelectValue] = useState<SelectOptionType>(values[0]);
  const [firstName, setFirstName] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [communication, setCommunication] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [dynamicLabelText, setDynamicLabelText] =
    useState<string>('Номер телефона');
  const [dynamicInputType, setDynamicInputType] = useState<'text' | 'mask'>(
    'mask',
  );
  const [dynamicMask, setDynamicMask] = useState<string | undefined>(
    '+7 (000) 000-00-00',
  );
  const [dynamicPlaceholder, setDynamicPlaceholder] =
    useState<string>('+7 (999) 990 00 90');
  const [successSend, setSuccessSend] = useState<boolean>(false);
  const [sendWithError, setSendWithError] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const schema = useMemo((): SchemaOf<ApplicationFormYupTypes> => {
    let validText = 'Некорректная почта';
    let regExp = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
    let requiredText = 'Заполните Email';

    if (selectValue.label !== 'Email') {
      regExp =
        /^(\+7|7|8)?[\s\-]?\(?[489]\d{2}\)?[\s\-]?\d{3}[\s\-]?\d{2}[\s\-]?\d{2}$/;
      validText = 'Некорректный номер';
      requiredText = 'Заполните номер';
    }

    return yup.object().shape({
      firstName: yup.string().required('Заполните имя'),
      company: yup.string().required('Заполните компанию'),
      communication: yup
        .string()
        .matches(regExp, validText)
        .required(requiredText),
    });
  }, [selectValue.label]);
  const { validationErrors, doValidate, validateAllFields } =
    useValidation<ApplicationFormYupTypes>(
      { firstName, communication, company },
      schema,
    );

  useEffect(() => {
    setCommunication('');
    if (selectValue.label === 'Email') {
      setDynamicLabelText('Ваш Email');
      setDynamicInputType('text');
      setDynamicMask(undefined);
      setDynamicPlaceholder('email@email.ru');
    } else {
      setDynamicLabelText('Номер телефона');
      setDynamicInputType('mask');
      setDynamicMask('+7 (000) 000-00-00');
      setDynamicPlaceholder('+7 (999) 990 00 90');
    }
  }, [selectValue.label]);

  const router = useRouter();

  const handlerForm = (): void => {
    if (typeof setShowForm === 'function') {
      setShowForm(false);
    }
  };

  const sendApplication = async (evt: FormEvent): Promise<void> => {
    evt.preventDefault();
    const isValidationPassed = await validateAllFields();

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    if (isValidationPassed) {
      setButtonLoading(true);

      try {
        let captchaToken = '';

        if (typeof window.grecaptcha !== 'undefined') {
          captchaToken = await grecaptcha.execute(recaptchaApiKey, {
            action: 'application_form_request',
          });
        }

        if (!captchaToken) {
          return Promise.reject('captchaToken error');
        }

        const response = await fetch('/api/gemini-form-request/', {
          method: 'POST',
          body: JSON.stringify({
            name: firstName,
            company: company,
            contactMethod: selectValue.label,
            contact: communication,
            comment: comment,
            url: router.asPath,
            captchaToken,
          }),
        });

        if (response.status !== 200) {
          setSendWithError(true);
          setButtonLoading(false);
          sendFormAnalytics(gtmEvents.sendApplicationFormErr);
          console.error('response ->>', response);
        } else {
          setSuccessSend(true);
          setButtonLoading(false);
          sendFormAnalytics(gtmEvents.sendApplicationFormSuccess);
        }

        setSuccessSend(true);
        setButtonLoading(false);
      } catch (error) {
        setSendWithError(true);
        setButtonLoading(false);
        sendFormAnalytics(gtmEvents.sendApplicationFormErr);
        console.error('error ->>', error);
      }
    }
  };

  const content = successSend ? (
    <SendStatusProviderForm
      setShowForm={setShowForm}
      sendWithError={sendWithError}
    />
  ) : (
    <form className={s.wrapper} onSubmit={sendApplication}>
      <div className={s.description}>
        <H4 className={s.h4}>Заявка на продукт</H4>
        <Body4 className={s.body4}>
          Укажите свои контакты, чтобы с вами связался специалист из нужного
          отдела.
        </Body4>
        {logo}
      </div>

      <div className={s.inputFields}>
        <InputText
          name="firstName"
          labelText="Имя"
          value={firstName}
          onChange={(newValue): void => {
            setFirstName(newValue);
          }}
          doValidate={doValidate}
          errorMessage={validationErrors.firstName}
          className={s.input}
        />

        <InputText
          name="company"
          labelText="Компания"
          value={company}
          onChange={(newValue): void => {
            setCompany(newValue);
          }}
          doValidate={doValidate}
          errorMessage={validationErrors.company}
          className={s.input}
        />

        <div className={s.flexBlock}>
          <SelectBasicStylized
            labelText="Способ связи"
            value={selectValue}
            options={values}
            onChange={(newValue): void => {
              setSelectValue(newValue);
            }}
          />
          <InputText
            name="communication"
            labelText={dynamicLabelText}
            value={communication}
            onChange={(newValue): void => {
              setCommunication(newValue);
            }}
            doValidate={doValidate}
            errorMessage={validationErrors.communication}
            type={dynamicInputType}
            mask={dynamicMask}
            placeholder={dynamicPlaceholder}
            className={s.phone}
            isShowErrorOnBlur
          />
        </div>

        <InputText
          name="textarea"
          type="textarea"
          labelText="Комментарии (необязательно)"
          value={comment}
          onChange={(newValue): void => {
            setComment(newValue);
          }}
          className={s.textarea}
        />

        <Caption className={s.caption}>
          Нажимая кнопку «Отправить» вы подтверждаете, что согласны на 
          <ALink
            className={s.link}
            href="https://www.a-3.ru/files/politika_obrabotki_i_zashiti_dannih.pdf"
            target="_blank"
          >
            обработку персональных данных.
          </ALink>
        </Caption>

        <Button
          look={ColorsEnum.blue}
          isDark
          className={s.button}
          type="submit"
          isLoading={buttonLoading}
        >
          Отправить
        </Button>
      </div>
    </form>
  );

  return (
    <Typograf>
      <style global jsx>
        {`
          body {
            overflow: hidden;
          }
        `}
      </style>

      {typeof setShowForm === 'function' && (
        <Cross onClick={handlerForm} className={s.cross} />
      )}
      <div className={s.backdrop} onClick={handlerForm} />
      <div className={s.container}>{content}</div>
    </Typograf>
  );
};

export default ApplicationForm;
