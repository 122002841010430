import type { DivProps } from 'app.types';
import { ColorsEnum } from 'app.types';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import type { FC, ReactElement } from 'react';
import React, { useRef, useState } from 'react';
import { useInViewport } from 'react-in-viewport';

import type { CodeSlide } from 'components/basic/CodeTyping';
import CodeTyping from 'components/basic/CodeTyping';
import Carousel from 'components/complex/Carousel';

import s from './CodeSlider.module.scss';

interface CodeSlideTypes {
  id: string;
  content: ReactElement;
  code: CodeSlide;
}

interface CodeSliderTypes extends DivProps {
  slides: CodeSlideTypes[];
  color: ColorsEnum;
}

function Slide({
  slide,
  isCurrentSlide,
  color,
}: {
  slide: CodeSlideTypes;
  isCurrentSlide: boolean;
  color: ColorsEnum;
}): ReactElement {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { inViewport } = useInViewport(wrapperRef);
  const inView = isCurrentSlide && inViewport;

  return (
    <div>
      <div className={cn(s.slideWrapper, s[`slideWrapper${color}`])}>
        <div className={cn(s.contentWrapper, s[`contentWrapper${color}`])}>
          {slide.content}
        </div>
        <div
          className={cn(s.codeWrapper, s[`codeWrapper${color}`])}
          ref={wrapperRef}
        >
          <CodeTyping
            code={slide.code}
            inView={inView}
            wrapperRef={wrapperRef}
            color={color}
          />
        </div>
      </div>
    </div>
  );
}

const CodeSlider: FC<CodeSliderTypes> = ({
  slides: _slides,
  className,
  color,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { clickAnalytics } = useAnalytics();

  function afterChange(current: number): void {
    setCurrentSlide(current);
  }

  const slides = _slides.map((slide, i) => {
    return (
      <Slide
        color={color}
        key={slide.id}
        slide={slide}
        isCurrentSlide={currentSlide === i}
      />
    );
  });

  function getDots(slickGoTo: (slideNumber: number) => void): JSX.Element {
    const dots = _slides.map((slide, i) => {
      const isCurrent = currentSlide === i;

      return (
        <div
          key={slide.id}
          onClick={(): void => {
            slickGoTo(i);
            clickAnalytics({
              name: slide.id,
              sourceComponent: 'code-slider-navigation-button',
            });
          }}
          className={cn(s.sliderDot, {
            [s.currentDot]: isCurrent,
            [s.currentDotPurple]: isCurrent && color === ColorsEnum.purple,
            [s.currentDotLilac]: isCurrent && color === ColorsEnum.lilac,
            [s.currentDotBlue]: isCurrent && color === ColorsEnum.blue,
            [s.sliderDotPurple]: color === ColorsEnum.purple,
            [s.sliderDotLilac]: color === ColorsEnum.lilac,
            [s.sliderDotBlue]: color === ColorsEnum.blue,
          })}
        >
          <div
            className={cn(s.dotNumber, {
              [s.dotNumberPurple]: color === ColorsEnum.purple,
              [s.dotNumberLilac]: color === ColorsEnum.lilac,
              [s.dotNumberBlue]: color === ColorsEnum.blue,
            })}
          >
            {i + 1}
          </div>
          {slide.id}
        </div>
      );
    });

    return <div className={s.dotsWrapper}>{dots}</div>;
  }

  return (
    <div className={cn(s.wrapper, className)}>
      <Carousel settings={{ afterChange }} customDots={getDots}>
        {slides}
      </Carousel>
    </div>
  );
};

export default CodeSlider;
