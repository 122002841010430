const pageUrls = {
  home: '/',
  auth: '/auth/signin/',
  banks: '/products/banks/',
  paymentServices: '/products/payment-services/',
  providers: '/products/providers/',
  providerAccount: '/products/provider-account/',
  paymentForm: '/products/payment-form/',
  paymentForServices: '/products/payment-for-services/',
  work: '/company/work/',
  contacts: '/company/contacts/',
  aboutEngine: '/engine/about/',
  lkPage: 'https://lk.a-3.ru/',
  //пример с параметром
  // offer: (variant?: keyof typeof variants): string => {
  //   if (variant) {
  //     return `/user/offer/?variant=${variant}`;
  //   }
  //
  //   return '/user/offer/';
  // },
};

export default pageUrls;
