import { ColorsEnum } from 'app.types';
import useAnalytics from 'hooks/useAnalytics';
import React from 'react';
import type { FC, ReactElement } from 'react';

import { gtmEvents } from 'constants/gtmEvent';

import Button from 'components/basic/Button';
import Dropdown from 'components/basic/Dropdown';
import s from 'components/complex/NavigationMenu/DesktopMenu/DesktopMenu.module.scss';
import MenuHeader, {
  MenuHeaderLink,
} from 'components/complex/NavigationMenu/MenuHeader';
import MenuItem from 'components/complex/NavigationMenu/MenuItem';
import MenuLabel from 'components/complex/NavigationMenu/MenuLabel';
import MenuLink from 'components/complex/NavigationMenu/MenuLink';
import { headerItems } from 'components/complex/NavigationMenu/data';
import type {
  HeaderItemTypes,
  NavigationTypes,
} from 'components/complex/NavigationMenu/types';

export const itemTypes = {
  bubble: MenuItem,
  label: MenuLabel,
  link: MenuLink,
};

const DropDownContent: FC<HeaderItemTypes> = ({ title, items }) => {
  const { clickAnalytics } = useAnalytics();

  return (
    <Dropdown
      getHeader={(className: string): ReactElement => {
        return <MenuHeader className={className} text={title} />;
      }}
      content={
        <>
          {items.map(item => {
            const { type, text, analyticsEvent } = item;
            const Component = itemTypes[type ?? 'label'];

            return (
              <Component
                key={String(text)}
                onClick={(): void => {
                  if (analyticsEvent) {
                    clickAnalytics(analyticsEvent);
                  }
                }}
                {...item}
              />
            );
          })}
        </>
      }
    />
  );
};

const DesktopMenu: FC<NavigationTypes> = ({ setShowForm }) => {
  const { clickAnalytics } = useAnalytics();

  return (
    <ul className={s.wrapper}>
      {headerItems.map(item => {
        return <DropDownContent key={item.id} {...item} />;
      })}

      <MenuHeaderLink
        text="Оставить заявку"
        onClick={(): void => {
          setShowForm(true);
          clickAnalytics(gtmEvents.clickHeaderApplication);
        }}
      />
      <Button
        look={ColorsEnum.blue}
        isDark
        href="https://www.a-3.ru/platezhi/"
        onClick={(): void => {
          clickAnalytics(gtmEvents.clickProceedPayment);
        }}
      >
        Перейти к оплате
      </Button>
    </ul>
  );
};

export default DesktopMenu;
