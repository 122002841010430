import { gtmEvents } from 'constants/gtmEvent';
import pagesUrls from 'constants/pagesUrls';

import type { ProductCardDataInfo } from 'components/basic/ProductCard/types';

export const productCardData: ProductCardDataInfo = {
  purple: {
    '--title-text': 'var(--text-white)',
    '--body-text': 'var(--light-purple-5)',
    '--white-label-bg': 'var(--light-purple-20)',
    '--white-label-text': 'var(--light-purple-100)',
    title: 'Подключение\n' + 'поставщиков услуг',
    body: 'Помогаем банкам проводить оплату клиентов в пользу более 60 тыс. компаний, предоставляющих услуги ЖКХ, телекоммуникации, оплаты штрафов ГИБДД и государственных начислений.',
    label: 'банки',
    link: pagesUrls.banks,
    analyticsEvent: gtmEvents.clickProductCardBanks,
  },

  marine: {
    '--title-text': 'var(--text-white)',
    '--body-text': 'var(--light-marine-5)',
    '--white-label-bg': 'var(--light-marine-20)',
    '--white-label-text': 'var(--light-marine-100)',
    title: 'Приём платежей\n' + 'в топ-банках страны',
    body: 'Оперативное подключение возможности отображать информацию о задолженностях и принимать платежи в крупнейших банковских приложениях и платежных сервисах.',
    label: 'поставщики услуг',
    link: pagesUrls.providers,
    analyticsEvent: gtmEvents.clickProductCardProviders,
  },

  blue: {
    '--title-text': 'var(--text-white)',
    '--body-text': 'var(--light-blue-10)',
    '--white-label-bg': 'var(--light-blue-10)',
    '--white-label-text': 'var(--light-blue-60)',
    title: 'Сайт для оплаты услуг',
    body: 'Сервис, чтобы найти и оплатить счета за услуги ЖКХ и мобильную связь, штрафы ГИБДД, налоги. Одновременно можно передать показания счётчиков на воду и свет.',
    label: 'оплата услуг',
    link: 'https://www.a-3.ru/platezhi/',
    analyticsEvent: gtmEvents.clickProductCardPlatezhi,
  },

  peach: {
    '--title-text': 'var(--text-white)',
    '--body-text': 'var(--light-peach-20)',
    '--white-label-bg': 'var(--light-peach-20)',
    '--white-label-text': 'var(--light-peach-100)',
    title: 'Виджет для клиентских платежей',
    body: 'Простой в интеграции способ принимать платежи от клиентов.',
    label: 'платёжная форма',
    link: pagesUrls.paymentForm,
    analyticsEvent: gtmEvents.clickProductCardPaymentForm,
  },

  lilac: {
    '--title-text': 'var(--text-white)',
    '--body-text': 'var(--light-lilac-10)',
    '--white-label-bg': 'var(--light-lilac-10)',
    '--white-label-text': 'var(--light-lilac-100)',
    title: 'Конструктор\n' + 'для оплаты услуг',
    body: 'Настраиваемая форма платежа для сайта или приложения, через которую клиенты могут оплатить любые услуги. Доступ к базе 60+ тыс. организаций и 122+ млн лицевых счетов.',
    label: 'платёжные сервисы',
    link: pagesUrls.paymentServices,
    analyticsEvent: gtmEvents.clickProductCardPaymentServices,
  },
};

export const providerAccountContent = {
  label: 'лк поставщика',
  title: 'Подключить платежи через банки без интеграции',
  body: 'Один договор позволяет подключить приём платежей в крупнейших банках и платёжных сервисах, платежи передаются в едином реестре.',
  link: pagesUrls.providerAccount,
  analyticsEvent: gtmEvents.clickProductCardProviderAccount,
  imgSrcDesktop: '/componentsImages/Hero/marine/providerAccount/desktop.svg',
  imgSrcMobile: '/componentsImages/Hero/marine/providerAccount/mobile.svg',
};
