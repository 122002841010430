import { ColorsEnum } from 'app.types';
import React, { useState } from 'react';
import type { FC } from 'react';

import { seoMain } from 'constants/seoText';

import FixedMenu from 'components/complex/FixedMenu';
import WantToTry from 'components/complex/WantToTry';
import ApplicationForm from 'components/complex/forms/ApplicationForm';
import Page from 'components/layouts/Page';
import BusinessSolutions from 'components/pagesComponents/main/components/BusinessSolutions';
import ClientsAndPartner from 'components/pagesComponents/main/components/ClientsAndPartner';
import HeroMain from 'components/pagesComponents/main/components/HeroMain';
import SimplifyProcesses from 'components/pagesComponents/main/components/SimplifyProcesses';
import WhyAreWe from 'components/pagesComponents/main/components/WhyAreWe';

import s from './MainPage.module.scss';

const MainPage: FC = () => {
  const [isFormShow, setShowForm] = useState<boolean>(false);

  return (
    <Page color={ColorsEnum.blue} seo={seoMain}>
      <div className={s.darkWrapper}>
        <FixedMenu color={ColorsEnum.blue} setShowForm={setShowForm} />
        {isFormShow && <ApplicationForm setShowForm={setShowForm} />}
        <HeroMain setShowForm={setShowForm} />
        <BusinessSolutions />
        <SimplifyProcesses />
        <WhyAreWe />
        <div className={s.wrapperWant}>
          <WantToTry
            color={ColorsEnum.blue}
            text="Бесплатная консультация специалиста по условиям подключения к платежному сервису А3"
            setShowForm={setShowForm}
          />
        </div>
        <ClientsAndPartner />
      </div>
    </Page>
  );
};

export default MainPage;
