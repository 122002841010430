import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import type { DivProps } from 'app.types';
import cn from 'classnames';
import React from 'react';
import type { FC } from 'react';

import { H2, Title1, Title3 } from 'components/basic/Typography';

import s from './NumericIndicator.module.scss';

interface NumericIndicatorTypes extends DivProps {
  separator: string;
  numbers: string;
  unit: string;
  description: string;
}

const NumericIndicator: FC<NumericIndicatorTypes> = ({
  separator,
  numbers,
  unit,
  description,
  className,
  ...restProps
}) => {
  return (
    <div className={cn(s.wrapper, className)} {...restProps}>
      <Typograf>
        {separator && (
          <div className={s.separator}>
            <Title1>{separator}</Title1>
          </div>
        )}

        <div className={s.wrapperNumbers}>
          <div className={s.number}>
            <H2>{numbers}</H2>
            {unit && <Title1>{unit}</Title1>}
          </div>

          <Title3 className={s.title3}>{description}</Title3>
        </div>
      </Typograf>
    </div>
  );
};

export default NumericIndicator;
