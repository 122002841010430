import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import type { DivProps } from 'app.types';
import cn from 'classnames';
import React from 'react';
import type { FC } from 'react';

import { Body3, Caption, Title3 } from 'components/basic/Typography';

import s from './ClientCard.module.scss';

interface ClientCardTypes extends DivProps {
  text: string;
  image: string;
  people: string;
  role: string;
}
const ClientCard: FC<ClientCardTypes> = ({
  text,
  image,
  people,
  className,
  role,
  ...restProps
}) => {
  return (
    <Typograf>
      <div className={cn(s.wrapper, className)} {...restProps}>
        <Body3 className={s.title}>{text}</Body3>
        <div className={s.wrapperImg}>
          <img
            className={s.img}
            src={`/componentsImages/MainPage/ClientsAndPartner/${image}`}
            alt="logo bank"
          />
          <div className={s.wrapperText}>
            <Title3 className={s.author}>{people}</Title3>
            <Caption className={s.role}>{role}</Caption>
          </div>
        </div>
      </div>
    </Typograf>
  );
};

export default ClientCard;
