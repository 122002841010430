import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import type { ColorsEnum } from 'app.types';
import useAnalytics from 'hooks/useAnalytics';
import React from 'react';
import type { FC } from 'react';

import { gtmEvents } from 'constants/gtmEvent';

import Button from 'components/basic/Button';
import ContentContainer from 'components/basic/ContentContainer';
import { Body4, H2 } from 'components/basic/Typography';
import { colors } from 'components/complex/WantToTry/constants';

import s from './WantToTry.module.scss';

interface WantToTryTypes {
  color: ColorsEnum;
  text: string;
  setShowForm: (value: boolean) => void;
  buttonText?: string;
}

const WantToTry: FC<WantToTryTypes> = ({
  color,
  text,
  setShowForm,
  buttonText,
}) => {
  const colorsVariables = colors[color] as React.CSSProperties;
  const { clickAnalytics } = useAnalytics();

  const buttonHandler = (): void => {
    clickAnalytics(gtmEvents.clickWantToTry);
    setShowForm(true);
  };

  return (
    <div
      className={s.wrapper}
      style={{
        backgroundImage: `url("/componentsImages/Hero/${color}/background.jpg")`,
        ...colorsVariables,
      }}
    >
      <ContentContainer className={s.contentContainer}>
        <Typograf>
          <H2 Tag="div" className={s.h2}>
            Хотите попробовать?
          </H2>
          <Body4 className={s.text}>{text}</Body4>
          <Button className={s.button} look={color} onClick={buttonHandler}>
            {buttonText ? buttonText : 'Попробовать'}
          </Button>
        </Typograf>
      </ContentContainer>
    </div>
  );
};

export default WantToTry;
