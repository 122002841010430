import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import { ColorsEnum } from 'app.types';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import React from 'react';
import type { FC } from 'react';

import { gtmEvents } from 'constants/gtmEvent';

import Button from 'components/basic/Button';
import { Body4, H4 } from 'components/basic/Typography';
import s from 'components/complex/forms/ApplicationForm/ApplicationForm.module.scss';
import type { SendStatusApplicationFormTypes } from 'components/complex/forms/ApplicationForm/components/SendStatusApplicationForm/types';
import Error from 'components/complex/forms/svg/error.svg';
import Success from 'components/complex/forms/svg/success.svg';

const SendStatusApplicationForm: FC<SendStatusApplicationFormTypes> = ({
  setShowForm,
  sendWithError,
}) => {
  const { clickAnalytics } = useAnalytics();

  const buttonHandler = (): void => {
    if (typeof setShowForm === 'function') {
      setShowForm(false);
      clickAnalytics(gtmEvents.hideApplicationForm);
    }
  };

  return (
    <Typograf>
      <div className={cn(s.wrapper, s.wrapperSuccess)}>
        {sendWithError ? (
          <>
            <div className={s.successICon}>
              <Error />
            </div>
            <H4 className={s.h4Success}>Что-то пошло не так...</H4>
            <Body4 className={s.body4Success}>
              Мы уже работаем над этим, попробуйте заполнить заявку немного
              позже.
            </Body4>
          </>
        ) : (
          <>
            <div className={s.successICon}>
              <Success />
            </div>
            <H4 className={s.h4Success}>Спасибо за заявку</H4>
            <Body4 className={s.body4Success}>
              Скоро с вами свяжется специалист а3. Обычно мы связываемся на
              следующий рабочий день.
            </Body4>
          </>
        )}

        <Button
          look={ColorsEnum.blue}
          isDark
          className={s.buttonSuccess}
          onClick={buttonHandler}
        >
          Вернуться назад
        </Button>
      </div>
    </Typograf>
  );
};

export default SendStatusApplicationForm;
