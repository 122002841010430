import cn from 'classnames';
import React, { useState } from 'react';
import type { FC, ReactElement } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import s from './Dropdown.module.scss';

interface Props {
  getHeader: (className: string) => ReactElement;
  content: ReactElement;
}

const Dropdown: FC<Props> = ({ getHeader, content }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const closed = useDebouncedCallback(() => {
    setMenuOpen(false);
  }, 100);

  return (
    <div style={{ position: 'relative' }}>
      <div
        className={cn({
          open: isMenuOpen,
        })}
        onMouseEnter={(): void => {
          closed.cancel();
          setMenuOpen(true);
        }}
        onMouseLeave={closed}
      >
        {getHeader(cn({ open: true }))}
      </div>
      <div
        className={cn(s.content, {
          [s.open]: isMenuOpen,
        })}
        onMouseEnter={(): void => {
          closed.cancel();
          setMenuOpen(true);
        }}
        onMouseLeave={closed}
      >
        {content}
      </div>
    </div>
  );
};

export default Dropdown;
