import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import React from 'react';
import type { FC } from 'react';

import ALink from 'components/basic/ALink';
import {
  productCardData,
  providerAccountContent,
} from 'components/basic/ProductCard/constants';
import type { ProductCardTypes } from 'components/basic/ProductCard/types';
import { Body5, Title1 } from 'components/basic/Typography';

import s from './ProductCard.module.scss';

const ProductCard: FC<ProductCardTypes> = ({
  color,
  className,
  forProviderAccount,
}) => {
  const { clickAnalytics } = useAnalytics();
  const colorsVariables = productCardData[color] as React.CSSProperties;
  const { title, body, label, link, analyticsEvent } = productCardData[color];

  const buttonHandler = (): void => {
    if (forProviderAccount) {
      clickAnalytics(providerAccountContent.analyticsEvent);
    } else {
      clickAnalytics(analyticsEvent);
    }
  };

  return (
    <Typograf>
      <ALink
        href={forProviderAccount ? providerAccountContent.link : link}
        onClick={buttonHandler}
        className={cn(s.wrapper, className)}
        style={{
          backgroundImage: `url("/componentsImages/Hero/${color}/background.jpg")`,
          ...colorsVariables,
        }}
      >
        <div className={s.text}>
          <div className={s.bubbles}>
            <div className={cn(s.label, s.whiteLabel)}>a3</div>
            <div className={cn(s.label, s.linkLabel)}>
              {forProviderAccount ? providerAccountContent.label : label}
            </div>
          </div>
          <Title1 className={s.title}>
            {forProviderAccount ? providerAccountContent.title : title}
          </Title1>
          <Body5 className={s.body5}>
            {forProviderAccount ? providerAccountContent.body : body}
          </Body5>
        </div>

        <div className={s.images}>
          <img
            src={
              forProviderAccount
                ? providerAccountContent.imgSrcDesktop
                : `/componentsImages/Hero/${color}/desktop.svg`
            }
            alt="imgDesktop"
            className={s.imgDesktop}
          />
          <img
            src={
              forProviderAccount
                ? providerAccountContent.imgSrcMobile
                : `/componentsImages/Hero/${color}/mobile.svg`
            }
            alt="imgMobile"
            className={s.imgMobile}
          />
        </div>
      </ALink>
    </Typograf>
  );
};

export default ProductCard;
