import type { DivProps } from 'app.types';
import cn from 'classnames';
import React from 'react';
import type { FC } from 'react';

import Carousel from 'components/complex/Carousel';
import ClientCard from 'components/pagesComponents/main/components/ClientsAndPartner/components/ClientCard';
import {
  sliderClientsSettings,
  slidesDataClients,
} from 'components/pagesComponents/main/components/ClientsAndPartner/components/SliderClient/data';
import CustomArrows from 'components/pagesComponents/products/banks/components/MadeTemplateBlock/FormsSlider/CustomArrows';

import s from './SliderClient.module.scss';

type SliderClientTypes = DivProps;
const SliderClient: FC<SliderClientTypes> = ({ className, ...restProps }) => {
  const Slides = slidesDataClients.map(({ text, img, people, role }) => {
    return (
      <ClientCard
        key={text}
        text={text}
        image={img}
        people={people}
        role={role}
      />
    );
  });

  return (
    <div className={cn(s.wrapper, className)} {...restProps}>
      <Carousel
        settings={sliderClientsSettings}
        customArrows={(previousSlide, nextSlide): JSX.Element => {
          return (
            <CustomArrows
              color="lightBlue"
              previousSlide={previousSlide}
              nextSlide={nextSlide}
              className={s.arrows}
            />
          );
        }}
      >
        {Slides}
      </Carousel>
    </div>
  );
};

export default SliderClient;
