import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import type { FC } from 'react';
import React from 'react';

import { gtmEvents } from 'constants/gtmEvent';

import Icon from 'components/basic/Icon';
import { IconTypes } from 'components/basic/Icon/types';
import s from 'components/pagesComponents/products/banks/components/MadeTemplateBlock/FormsSlider/CustomArrows/CustomArrows.module.scss';
import type { CustomArrowsTypes } from 'components/pagesComponents/products/banks/components/MadeTemplateBlock/FormsSlider/CustomArrows/types';

const CustomArrows: FC<CustomArrowsTypes> = ({
  previousSlide,
  nextSlide,
  color = 'white',
  className,
  ...restProps
}) => {
  const { clickAnalytics } = useAnalytics();

  const arrowPrevHandler = (): void => {
    clickAnalytics(gtmEvents.clickArrowPrevious);
  };

  const arrowNextHandler = (): void => {
    clickAnalytics(gtmEvents.clickArrowNext);
  };

  return (
    <div className={cn(s.arrowsContainer, className)} {...restProps}>
      <div
        className={cn(s.arrowWrapper, {
          [s[color]]: color,
        })}
        onClick={previousSlide}
      >
        <Icon icon={IconTypes.arrowPrevious} onClick={arrowPrevHandler} />
      </div>
      <div
        className={cn(s.arrowWrapper, {
          [s[color]]: color,
        })}
        onClick={nextSlide}
      >
        <Icon icon={IconTypes.arrowNext} onClick={arrowNextHandler} />
      </div>
    </div>
  );
};

export default CustomArrows;
