import { ColorsEnum } from 'app.types';
import type { DivProps } from 'app.types';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import React, { useEffect, useState } from 'react';
import type { FC } from 'react';

import { gtmEvents } from 'constants/gtmEvent';
import pagesUrls from 'constants/pagesUrls';

import ALink from 'components/basic/ALink';
import Button from 'components/basic/Button';
// import EngineSphere from 'components/basic/EngineSphere';
import Icon from 'components/basic/Icon';
import { IconTypes } from 'components/basic/Icon/types';
import NavigationMenu from 'components/complex/NavigationMenu';

import s from './FixedMenu.module.scss';

type FixedMenuTypes = {
  color: ColorsEnum;
  setShowForm: (value: boolean) => void;
} & DivProps;

const FixedMenu: FC<FixedMenuTypes> = ({ className, setShowForm }) => {
  const [isShowMenu, setShowMenu] = useState(false);
  const { clickAnalytics } = useAnalytics();
  useEffect(() => {
    const listener = (): void => {
      if (window.scrollY > 200) {
        setShowMenu(true);
      } else {
        setShowMenu(false);
      }
    };

    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  return (
    <>
      <div
        className={cn(s.wrapper, 'fixedMenu', className, {
          [s.open]: isShowMenu,
        })}
      >
        <ALink href={pagesUrls.home} className={s.link}>
          <Icon icon={IconTypes.logo} className={s.mainLogo} />
        </ALink>

        <NavigationMenu setShowForm={setShowForm} />

        <Button
          look={ColorsEnum.blue}
          className={s.mobileButton}
          isDark
          href="https://www.a-3.ru/platezhi/"
          onClick={(): void => {
            clickAnalytics(gtmEvents.clickProceedPayment);
          }}
        >
          Перейти к оплате
        </Button>
      </div>
      {/*<EngineSphere />*/}
    </>
  );
};

export default FixedMenu;

Math.random();
