import { ColorsEnum } from 'app.types';

import { gtmEvents } from 'constants/gtmEvent';
import pageUrls from 'constants/pagesUrls';

import type { HeaderItemTypes } from 'components/complex/NavigationMenu/types';

export const headerItems: HeaderItemTypes[] = [
  {
    title: 'Продукты',
    id: 'Header_products',
    items: [
      {
        type: 'label',
        text: 'Продукты для бизнеса',
      },
      {
        type: 'bubble',
        color: ColorsEnum.purple,
        text: 'банки',
        href: pageUrls.banks,
        analyticsEvent: gtmEvents.clickHeaderBanks,
      },
      {
        type: 'bubble',
        color: ColorsEnum.lilac,
        text: 'платёжные сервисы',
        href: pageUrls.paymentServices,
        analyticsEvent: gtmEvents.clickHeaderPaymentServices,
      },
      {
        type: 'bubble',
        color: ColorsEnum.marine,
        text: 'поставщики услуг',
        href: pageUrls.providers,
        analyticsEvent: gtmEvents.clickHeaderProviders,
      },
      {
        type: 'bubble',
        color: ColorsEnum.marine,
        text: 'лк поставщика',
        href: pageUrls.providerAccount,
        analyticsEvent: gtmEvents.clickHeaderProviderAccount,
      },
      //Комментим так как страница не готова к релизу
      // {
      //   type: 'bubble',
      //   color: ColorsEnum.peach,
      //   text: 'платёжная форма',
      //   href: pageUrls.paymentForm,
      // },
      {
        type: 'label',
        text: 'Продукты для физических лиц',
      },
      {
        type: 'bubble',
        color: ColorsEnum.blue,
        text: 'оплата услуг',
        href: 'https://www.a-3.ru/platezhi/',
        analyticsEvent: gtmEvents.clickHeaderPlatezhi,
      },
    ],
  },
  {
    title: 'Компания',
    id: 'Header_company',
    items: [
      {
        type: 'link',
        text: 'Работа у нас',
        href: pageUrls.work,
        analyticsEvent: gtmEvents.clickHeaderWork,
      },
      {
        type: 'link',
        text: 'Контакты',
        href: pageUrls.contacts,
        analyticsEvent: gtmEvents.clickHeaderContacts,
      },
    ],
  },
  {
    title: 'a3.Engine',
    id: 'Header_engine',
    items: [
      {
        type: 'link',
        text: 'О технологии',
        href: pageUrls.aboutEngine,
        analyticsEvent: gtmEvents.clickHeaderEngine,
      },
      // {
      //   type: 'link',
      //   text: <>Документация</>,
      //   href: pageUrls.aboutEngine,
      //   analyticsEvent: gtmEvents.clickHeaderEngineDoc,
      // },
    ],
  },
];
