import { ColorsEnum } from 'app.types';
import React from 'react';

import Footer from 'components/layouts/Footer';
import { HtmlHead } from 'components/layouts/HtmlHead';

import type { PageProps } from './types';

export default function Page({
  children,
  seo,
  withoutFooter,
  color = ColorsEnum.purple,
  isLightFooter,
  className,
}: PageProps): JSX.Element {
  return (
    <>
      <HtmlHead {...seo} />
      <main className={className}>{children}</main>
      {!withoutFooter && <Footer isLight={isLightFooter} color={color} />}
    </>
  );
}
