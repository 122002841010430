import cn from 'classnames';
import type { FC } from 'react';
import React from 'react';

import ALink from 'components/basic/ALink';
import s from 'components/complex/NavigationMenu/MenuHeader/MenuHeader.module.scss';
import Aim from 'components/complex/NavigationMenu/MenuHeader/svg/aim.svg';
import HalfAim from 'components/complex/NavigationMenu/MenuHeader/svg/halfAim.svg';
import type {
  MenuHeaderLinkTypes,
  MenuHeaderTypes,
} from 'components/complex/NavigationMenu/types';

const MenuHeader: FC<MenuHeaderTypes> = ({ text, className }) => {
  return (
    <div className={cn(s.wrapper, className)}>
      {text}
      <Aim className={s.aim} />
      <HalfAim className={s.halfAim} />
    </div>
  );
};

export const MenuHeaderLink: FC<MenuHeaderLinkTypes> = ({
  text,
  href = '',
  onClick,
}) => {
  return (
    <li className={cn(s.wrapper, s.linkWrapper)}>
      <ALink href={href} className={s.link} onClick={onClick}>
        {text}
      </ALink>
    </li>
  );
};

export default MenuHeader;
