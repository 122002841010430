import type { ColorsEnum } from 'app.types';
import React, { useCallback, useEffect, useState } from 'react';
import type { FC, RefObject } from 'react';

import SimpleTaping from 'components/basic/CodeTyping/SimpleTaping';

import s from './CodeTyping.module.scss';

export interface CodeSlide {
  request: {
    text: string;
    language: string;
  };
  response: { text: string; language: string };
}

interface CodeTypingTypes {
  code: CodeSlide;
  inView: boolean;
  wrapperRef: RefObject<HTMLDivElement>;
  color: ColorsEnum;
}

const CodeTyping: FC<CodeTypingTypes> = ({
  code,
  inView,
  wrapperRef,
  // color,
}) => {
  const [currentTyping, setCurrentTyping] = useState<
    'request' | 'response' | null
  >(null);
  const onTypingDone = useCallback(id => {
    if (id === 'request') {
      setCurrentTyping('response');
    }
  }, []);

  useEffect(() => {
    if (inView) {
      setCurrentTyping('request');
    } else {
      setCurrentTyping(null);
    }
  }, [inView]);

  return (
    <div className={s.wrapper}>
      <div className={s.description}>Request</div>

      <SimpleTaping
        id="request"
        wrapperRef={wrapperRef}
        currentTyping={currentTyping}
        onTypingDone={onTypingDone}
        text={code.request.text}
        language={code.request.language}
      />
      <div className={s.description}>Response</div>
      <SimpleTaping
        id="response"
        wrapperRef={wrapperRef}
        currentTyping={currentTyping}
        onTypingDone={onTypingDone}
        text={code.response.text}
        language={code.response.language}
      />
    </div>
  );
};

export default CodeTyping;
