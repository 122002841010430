import useAnalytics from 'hooks/useAnalytics';
import React, { useState } from 'react';
import type { FC } from 'react';

import { gtmEvents } from 'constants/gtmEvent';
import pageUrls from 'constants/pagesUrls';

import ALink from 'components/basic/ALink';
import Cross from 'components/basic/Cross';
import Icon from 'components/basic/Icon';
import { IconTypes } from 'components/basic/Icon/types';
import { itemTypes } from 'components/complex/NavigationMenu/DesktopMenu';
import MenuLabel from 'components/complex/NavigationMenu/MenuLabel';
import MenuLink from 'components/complex/NavigationMenu/MenuLink';
import s from 'components/complex/NavigationMenu/MobileMenu/MobileMenu.module.scss';
import { headerItems } from 'components/complex/NavigationMenu/data';
import type {
  HeaderItemTypes,
  NavigationTypes,
} from 'components/complex/NavigationMenu/types';

const MenuGroup: FC<HeaderItemTypes> = ({ title, items, id }) => {
  const { clickAnalytics } = useAnalytics();
  let header: JSX.Element | null = null;

  if (id === 'Header_company' || id === 'Header_engine') {
    header = <MenuLabel type="label" text={title} />;
  }

  return (
    <div className={s.group} data-group={id} style={{ gridArea: id }}>
      {header}
      {items.map(item => {
        const { text, type, analyticsEvent } = item;
        const Component = itemTypes[type ?? 'label'];

        return (
          <Component
            key={String(text)}
            {...item}
            onClick={(): void => {
              if (analyticsEvent) {
                clickAnalytics(analyticsEvent);
              }
            }}
          />
        );
      })}
    </div>
  );
};

const MobileMenu: FC<NavigationTypes> = ({ setShowForm }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { clickAnalytics } = useAnalytics();

  function toggleMenu(): void {
    setIsOpen(open => !open);
  }

  return (
    <div className={s.wrapper}>
      <Cross isOpen={isOpen} onClick={toggleMenu} isForMobileMenu />
      {isOpen && (
        <ul className={s.itemsWrapper}>
          <style global jsx>
            {`
              body {
                overflow: hidden;
              }

              .fixedMenu {
                animation: none; //без этого элементы с position:fixed не выходят за пределы блока и обрезается, как будто у контейнера задан overflow:hidden
              }
            `}
          </style>
          <div className={s.header}>
            <ALink href={pageUrls.home} className={s.link}>
              <Icon
                icon={IconTypes.logo}
                size={52}
                className={s.logo}
                color="light-blue-100"
              />
            </ALink>
          </div>
          <div className={s.content}>
            {headerItems.map(item => {
              return <MenuGroup key={item.id} {...item} />;
            })}
            <div className={s.group} style={{ gridArea: 'payments' }}>
              <MenuLink
                onCLick={(): void => {
                  setShowForm(true);
                  clickAnalytics(gtmEvents.clickHeaderApplication);
                }}
                text={<>Оставить заявку</>}
              />
            </div>
          </div>
        </ul>
      )}
    </div>
  );
};

export default MobileMenu;
