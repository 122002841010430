export const colors = {
  purple: {
    '--headerText': 'var(--light-purple-20)',
    '--bodyText': 'var(--light-purple-5)',
  },
  marine: {
    '--headerText': 'var(--light-marine-20)',
    '--bodyText': 'var(--light-marine-20)',
  },
  blue: {
    '--headerText': 'var(--light-marine-10)',
    '--bodyText': 'var(--light-marine-30)',
  },
  peach: {
    '--headerText': 'var(--light-marine-10)',
    '--bodyText': 'var(--light-marine-30)',
  },
  lilac: {
    '--headerText': 'var(--light-lilac-5)',
    '--bodyText': 'var(--light-lilac-10)',
  },
};
