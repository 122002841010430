import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import { useRouter } from 'next/router';
import React from 'react';
import type { FC } from 'react';

import ALink from 'components/basic/ALink';
import s from 'components/complex/NavigationMenu/MenuLink/MenuLink.module.scss';
import type { ItemsTypes } from 'components/complex/NavigationMenu/types';

const MenuLink: FC<ItemsTypes> = ({ href, text, analyticsEvent, onCLick }) => {
  const { clickAnalytics } = useAnalytics();
  const router = useRouter();

  return (
    <li className={s.liItem} onClick={onCLick}>
      <ALink
        href={href}
        className={cn(s.link, {
          [s.current]: href?.includes(router.asPath) && router.asPath !== '/',
        })}
        onClick={(): void => {
          if (analyticsEvent) {
            clickAnalytics(analyticsEvent);
          }
        }}
      >
        {text}
      </ALink>
    </li>
  );
};

export default MenuLink;
