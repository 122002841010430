import React from 'react';
import type { FC } from 'react';

import pageUrls from 'constants/pagesUrls';

import ALink from 'components/basic/ALink';
import ContentContainer from 'components/basic/ContentContainer';
import Icon from 'components/basic/Icon';
import { IconTypes } from 'components/basic/Icon/types';
import DesktopMenu from 'components/complex/NavigationMenu/DesktopMenu';
import MobileMenu from 'components/complex/NavigationMenu/MobileMenu';
import s from 'components/complex/NavigationMenu/NavigationMenu.module.scss';
import type { NavigationTypes } from 'components/complex/NavigationMenu/types';

const NavigationMenu: FC<NavigationTypes> = ({ setShowForm }) => {
  return (
    <ContentContainer className={s.wrapper}>
      <ALink href={pageUrls.home} className={s.link}>
        <Icon icon={IconTypes.logo} className={s.mainLogo} />
      </ALink>
      <DesktopMenu setShowForm={setShowForm} />
      <MobileMenu setShowForm={setShowForm} />
    </ContentContainer>
  );
};

export default NavigationMenu;
