export interface GtmClickEventType {
  event: string;
  sourcePage: string;
  sourceComponent: string;
  name: string;
  SBN: string;
}

export interface GtmSendFormEventType {
  sourcePage: string;
  sourceComponent: string;
  event: 'a3_send_form';
  name: string;
  result: string;
  SBN: '3';
}

export const gtmEvents = {
  clickProductCardBanks: {
    sourceComponent: 'card',
    name: 'Банки',
  },
  clickProductCardProviders: {
    sourceComponent: 'card',
    name: 'Поставщики услуг',
  },
  clickProductCardPlatezhi: {
    sourceComponent: 'card',
    name: 'Оплата услуг',
  },
  clickProductCardPaymentForm: {
    sourceComponent: 'card',
    name: 'Платежная форма',
  },
  clickProductCardPaymentServices: {
    sourceComponent: 'card',
    name: 'Платежные сервисы',
  },
  clickProductCardProviderAccount: {
    sourceComponent: 'card',
    name: 'лк поставщика',
  },
  clickHeaderBanks: {
    sourceComponent: 'header',
    name: 'Банки',
  },
  clickHeaderPaymentServices: {
    sourceComponent: 'header',
    name: 'Платежные сервисы',
  },
  clickHeaderProviders: {
    sourceComponent: 'header',
    name: 'Поставщики услуг',
  },
  clickHeaderProviderAccount: {
    sourceComponent: 'header',
    name: 'лк поставщика',
  },
  clickHeaderPlatezhi: {
    sourceComponent: 'header',
    name: 'Платежи онлайн',
  },
  clickProceedPayment: {
    sourceComponent: 'header',
    name: 'Перейти к оплате',
  },
  clickHeaderApplication: {
    sourceComponent: 'header',
    name: 'Оставить заявку',
  },
  clickHeaderWork: {
    sourceComponent: 'header',
    name: 'Работа у нас',
  },
  clickHeaderContacts: {
    sourceComponent: 'header',
    name: 'Контакты',
  },
  clickHeaderEngine: {
    sourceComponent: 'header',
    name: 'Engine',
  },
  clickHeaderEngineDoc: {
    sourceComponent: 'header',
    name: 'Engine документация',
  },
  clickFooterBanks: {
    sourceComponent: 'footer-link',
    name: 'банки',
  },
  clickFooterPaymentServices: {
    sourceComponent: 'footer-link',
    name: 'платежные сервисы',
  },
  clickFooterProviders: {
    sourceComponent: 'footer-link',
    name: 'поставщики услуг',
  },
  clickFooterProviderAccount: {
    sourceComponent: 'footer-link',
    name: 'лк поставщика',
  },
  clickFooterPlatezhi: {
    sourceComponent: 'footer-link',
    name: 'оплата услуг',
  },
  clickFooterWork: {
    sourceComponent: 'footer-link',
    name: 'работа у нас',
  },
  clickFooterContacts: {
    sourceComponent: 'footer-link',
    name: 'контакты',
  },
  clickFooterEngine: {
    sourceComponent: 'footer-link',
    name: 'Engine',
  },
  clickFooterEngineDoc: {
    sourceComponent: 'footer-link',
    name: 'Engine документация',
  },
  clickFooterA3Phone: {
    sourceComponent: 'footer-link',
    name: 'номер телефона а-3',
  },
  clickFooterBetaSite: {
    sourceComponent: 'footer-link',
    name: 'Сайт беты',
  },
  clickAppStore: {
    sourceComponent: 'button',
    name: 'AppStore',
  },
  clickGoogleStore: {
    sourceComponent: 'button',
    name: 'GoogleStore',
  },
  clickRuStore: {
    sourceComponent: 'button',
    name: 'RuStore',
  },
  clickWantToTry: {
    sourceComponent: 'button',
    name: 'Попробовать',
  },
  clickArrowPrevious: {
    sourceComponent: 'reviews-slider-button',
    name: 'Стрелка влево',
  },
  clickArrowNext: {
    sourceComponent: 'reviews-slider-button',
    name: 'Стрелка вправо',
  },
  clickButtonTryHero: {
    sourceComponent: 'try button',
    name: 'топ страницы',
  },
  clickProviderAccountTryFourSteps: {
    sourceComponent: 'try button',
    name: '4 шага',
  },
  sendApplicationFormSuccess: {
    event: 'a3_send_form',
    sourceComponent: 'form',
    name: 'ApplicationForm',
    result: 'success',
    SBN: '3',
  },
  sendApplicationFormErr: {
    event: 'a3_send_form',
    sourceComponent: 'form',
    name: 'ApplicationForm',
    result: 'failed',
  },
  hideApplicationForm: {
    name: 'Вернуться назад',
    sourceComponent: 'button',
  },
  sendResumeFormSuccess: {
    event: 'a3_send_form',
    name: 'ResumeForm',
    sourceComponent: 'form',
    result: 'success',
  },
  sendResumeFormErr: {
    event: 'a3_send_form',
    name: 'ResumeForm',
    sourceComponent: 'form',
    result: 'failed',
  },
  sendProviderFormStep1: {
    name: 'lk_application_step1',
    sourceComponent: 'form',
    result: 'success',
  },
  sendProviderFormStep2: {
    name: 'lk_application_step2',
    sourceComponent: 'form',
    result: 'success',
  },
  sendProviderFormStep3: {
    name: 'lk_application_step3',
    sourceComponent: 'form',
    result: 'success',
  },
  sendProviderFormStep4: {
    name: 'lk_application_step4',
    sourceComponent: 'form',
    result: 'success',
  },
  hideProviderForm: {
    name: 'Вернутся на сайт',
    sourceComponent: 'form',
  },
  clickProviderFormStep2Back: {
    name: 'Вернутся на шаг 1',
    sourceComponent: 'form',
  },
  clickProviderFormStep3Back: {
    name: 'Вернутся на шаг 2',
    sourceComponent: 'form',
  },
  clickProviderFormStep4Back: {
    name: 'Вернутся на шаг 3',
    sourceComponent: 'form',
  },
  workPage: {
    toVacancies: {
      sourceComponent: 'header',
      name: 'К вакансиям',
    },
    showMap: {
      sourceComponent: 'link',
      name: 'Показать на карте',
    },
    sendResumeButton: {
      sourceComponent: 'button',
      name: 'кнопка Отправить резюме',
    },
    sendSendResumeFormSuccess: {
      event: 'a3_send_form',
      sourceComponent: 'form',
      name: 'ResumeForm',
      result: 'success',
      SBN: '3',
    },
    sendSendResumeFormError: {
      event: 'a3_send_form',
      sourceComponent: 'form',
      name: 'ResumeForm',
      result: 'failed',
      SBN: '3',
    },
  },
};
