import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import { ColorsEnum } from 'app.types';
import cn from 'classnames';
import type { FC } from 'react';
import React from 'react';

import ContentContainer from 'components/basic/ContentContainer';
import ProductCard from 'components/basic/ProductCard';
import { Body4, H3 } from 'components/basic/Typography';
import IndividualService from 'components/pagesComponents/main/components/BusinessSolutions/components/IndividualService';

import s from './BusinessSolutions.module.scss';

const BusinessSolutions: FC = () => {
  return (
    <Typograf>
      <ContentContainer>
        <div className={s.text}>
          <H3 className={s.h3}>
            Решения для <span className="text-gradient-blue">бизнеса</span>
          </H3>
          <Body4 className={s.body4}>
            Продуктовые сборки а3 для решения бизнес-задач
          </Body4>
        </div>
      </ContentContainer>

      <ContentContainer className={s.wrapper} grid>
        <ProductCard
          color={ColorsEnum.purple}
          className={cn(s.productCard, 'g-col-m-6 g-col-s-4 g-col-xss-8')}
        />
        <ProductCard
          color={ColorsEnum.lilac}
          className={cn(s.productCard, 'g-col-m-6 g-col-s-4 g-col-xss-8')}
        />
        <ProductCard
          color={ColorsEnum.marine}
          className={cn(s.productCard, 'g-col-m-6 g-col-s-4 g-col-xss-8')}
        />
        <ProductCard
          color={ColorsEnum.marine}
          className={cn(s.productCard, 'g-col-m-6 g-col-s-4 g-col-xss-8')}
          forProviderAccount
        />
      </ContentContainer>

      <IndividualService />
    </Typograf>
  );
};

export default BusinessSolutions;
