import type { CSSObject } from '@emotion/serialize';
import cn from 'classnames';
import type { ReactElement } from 'react';
import React, { useCallback, useState } from 'react';
import Select, { components } from 'react-select';
import type { Props as OriginalProps, InputProps } from 'react-select';

import s from 'components/basic/Select/Select.module.scss';
import type {
  SelectOptionType,
  SelectOnChangeType,
} from 'components/basic/Select/types';

interface Props extends OriginalProps {
  labelText?: string;
  placeholder?: string;
  errorMessage?: string;
  value: SelectOptionType | undefined;
  onChange?: SelectOnChangeType;
  externalComponents?: Partial<typeof components>;
  noOptionsMessage?:
    | ((obj: { inputValue: string }) => string | null)
    | undefined;
}
const customStyles = {
  singleValue: (provided: CSSObject): CSSObject => ({
    ...provided,
    fontSize: 16,
    paddingLeft: 1,
    paddingTop: 20,
    marginLeft: 0,
    paddingBottom: 6,
  }),
};

export default function SelectBasic({
  labelText,
  placeholder,
  errorMessage,
  options,
  noOptionsMessage,
  value,
  externalComponents,
  onChange,
  ...restProps
}: Props): JSX.Element {
  const hasPlaceholder = placeholder !== undefined && placeholder.length > 0;
  const [isFocused, setIsFocused] = useState(false);
  const Input = useCallback(
    (props: InputProps<unknown, boolean>): ReactElement => {
      return (
        <components.Input
          {...props}
          onFocus={(e): void => {
            setIsFocused(true);
            if (typeof props.onFocus !== 'undefined') {
              props.onFocus(e);
            }
          }}
          onBlur={(e): void => {
            setIsFocused(false);
            if (typeof props.onBlur !== 'undefined') {
              props.onBlur(e);
            }
          }}
        />
      );
    },
    [],
  );

  return (
    <div className={cn({ [s.label]: labelText })}>
      <Select
        instanceId={labelText ?? placeholder}
        styles={labelText ? customStyles : undefined}
        value={value}
        onChange={onChange}
        options={options}
        noOptionsMessage={noOptionsMessage}
        placeholder={placeholder ? placeholder : null}
        components={{
          IndicatorSeparator: (): null => null,
          Input,
          ...externalComponents,
        }}
        className={cn('select-container', {
          withError: errorMessage,
        })}
        classNamePrefix="select"
        {...restProps}
      />
      <div
        className={cn(
          s.floatLabel,
          {
            [s.activeLabel]: hasPlaceholder || !!value || isFocused,
          },
          'select__float-label',
        )}
      >
        {labelText}
      </div>
      <div
        className={cn(s.helpText, {
          [s.withError]: errorMessage,
        })}
      >
        {errorMessage}
      </div>
    </div>
  );
}
