import React from 'react';

import type { IIconProps } from 'components/basic/Icon/types';

export const Icon: React.FC<IIconProps> = props => {
  const { icon, size, color, ...restProps } = props;
  let style = {};

  if (color) {
    style = { color: `var(--${color})` };
  }

  return (
    <svg
      width={size}
      height={size}
      style={style}
      {...restProps}
      data-icon={icon}
    >
      <use xlinkHref={`#${icon}`} />
    </svg>
  );
};

export default Icon;
