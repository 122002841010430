import { useCallback, useState } from 'react';
import type { SchemaOf } from 'yup';

import { oneFieldValidation, simpleValidation } from 'utils/simpleValidation';

export interface UseValidationReturned<ObjectShape> {
  validationErrors: ErrorRecord; //объект ошибок {поле, сообщение}
  doValidate: (field: keyof ObjectShape, value: string) => Promise<void>; //валидация одного поля
  validateAllFields: () => Promise<boolean>; //валидация всех полей
  setErrors: (field: ErrorRecord) => void;
}

type ErrorRecord = Record<string, string>;

export default function useValidation<ObjectShape>(
  object: ObjectShape,
  schema: SchemaOf<ObjectShape>,
): UseValidationReturned<ObjectShape> {
  const [errors, setErrors] = useState<ErrorRecord>({});
  const processErrors = useCallback(
    (
      foundErrors: { inner: { path: string; message: string }[] } | undefined,
      field?: keyof ObjectShape,
    ): boolean => {
      let newErrors: ErrorRecord = { ...errors };
      let isHaveValidationErrors = false;

      if (foundErrors) {
        isHaveValidationErrors = true;

        //inner — это массив объектов ошибок, который отдаёт yup
        foundErrors.inner.forEach(({ path, message }) => {
          newErrors[path] = message;
        });
      } else {
        if (field) {
          //создаём объект, в котором есть все поля ошибок, кроме целевого
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [field]: value, ...errorsWithoutField } = newErrors;

          newErrors = errorsWithoutField as ErrorRecord;
        }
      }

      if (Object.keys(newErrors).length === 0) {
        isHaveValidationErrors = false;
      }

      setErrors(newErrors);

      return isHaveValidationErrors;
    },
    [errors],
  );
  const doValidate = useCallback(
    async (field: keyof ObjectShape, value) => {
      const newObject = { ...object, [field]: value };
      const foundErrors = await oneFieldValidation<ObjectShape>(
        field,
        newObject,
        schema,
      );

      processErrors(foundErrors, field);
    },
    [object, processErrors, schema],
  );

  async function validateAllFields(): Promise<boolean> {
    const foundErrors = await simpleValidation<ObjectShape>(object, schema);

    return !processErrors(foundErrors);
  }

  return {
    setErrors: setErrors,
    validationErrors: errors,
    doValidate,
    validateAllFields,
  };
}
