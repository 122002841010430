import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import cn from 'classnames';
import React from 'react';
import type { FC } from 'react';

import ContentContainer from 'components/basic/ContentContainer';
import Ticker from 'components/basic/Ticker';
import { Body3, H2 } from 'components/basic/Typography';
import SliderClient from 'components/pagesComponents/main/components/ClientsAndPartner/components/SliderClient';

import s from './ClientsAndPartner.module.scss';

const ClientsAndPartner: FC = () => {
  return (
    <Typograf>
      <div className={s.wrapper}>
        <ContentContainer grid>
          <H2 className={cn(s.head, 'g-start-1 g-col-m-5 g-col-xss-8')}>
            Клиенты <br />
            <span className="text-gradient-light-blue">и партнеры</span>
          </H2>
          <Body3
            className={cn(
              s.description,
              'g-start-m-9 g-start-xss-1 g-col-m-4 g-col-xss-8',
            )}
          >
            А3 — надёжный партнер для развития бизнеса. Пользователи платёжного
            сервиса А3 уже оценили все преимущества совместной работы:
          </Body3>
          <SliderClient className={cn(s.slider, 'g-col-m-12 g-col-xss-8')} />
        </ContentContainer>
        <div className={s.tickerWrapper}>
          <Ticker
            content={
              <img
                className={s.tickerImages}
                src="/componentsImages/MainPage/ClientsAndPartner/oneLine.svg"
                alt="Бегущая строка: Штраф, транспорт, телефон, спорт"
              />
            }
          />
          <Ticker
            content={
              <img
                className={cn(s.tickerImages, s.mobileHidden)}
                src="/componentsImages/MainPage/ClientsAndPartner/secondLine.svg"
                alt="Бегущая строка: газ, интернет, тв, вода, свет"
              />
            }
            speedInSecond={30}
          />
          <Ticker
            content={
              <img
                className={cn(s.tickerImages, s.mobileHidden)}
                src="/componentsImages/MainPage/ClientsAndPartner/thirdLine.svg"
                alt="Бегущая строка: электроэнергия, налог, обучение"
              />
            }
          />
        </div>
      </div>
    </Typograf>
  );
};

export default ClientsAndPartner;
