import cn from 'classnames';
import React from 'react';
import type { FC } from 'react';

import s from 'components/basic/Cross/Cross.module.scss';
import type { CrossTypes } from 'components/basic/Cross/types';
import Icon from 'components/basic/Icon';
import { IconTypes } from 'components/basic/Icon/types';

const Cross: FC<CrossTypes> = ({
  onClick,
  isOpen,
  isForMobileMenu = false,
  className,
  ...restProps
}) => {
  return isForMobileMenu ? (
    <div
      className={cn(s.cross, {
        [s.openedMenu]: isOpen,
        className,
      })}
      onClick={onClick}
      {...restProps}
    >
      {isOpen ? (
        <Icon icon={IconTypes.cross} size={28} color="text-black-100" />
      ) : (
        <Icon icon={IconTypes.menu} size={28} color="text-black-100" />
      )}
    </div>
  ) : (
    <div className={cn(s.cross, className)} onClick={onClick} {...restProps}>
      <Icon icon={IconTypes.cross} size={28} color="text-black-100" />
    </div>
  );
};

export default Cross;
