import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import type {
  GtmClickEventType,
  GtmSendFormEventType,
} from 'constants/gtmEvent';
import pageUrls from 'constants/pagesUrls';

import pushGmtEvent from 'utils/pushGmtEvent';

export type ClickEvent = Pick<GtmClickEventType, 'sourceComponent' | 'name'>;
export type SendFormEvent = Pick<
  GtmSendFormEventType,
  'result' | 'name' | 'sourceComponent'
>;

export interface ScrollEvent {
  percent: number;
}
export type ClickAnalyticsFunctionType = (event: ClickEvent) => void;
export type SendFormAnalyticsFunctionType = (event: SendFormEvent) => void;
export type ScrollAnalyticsFunctionType = (event: ScrollEvent) => void;

export default function useAnalytics(): {
  clickAnalytics: ClickAnalyticsFunctionType;
  sendFormAnalytics: SendFormAnalyticsFunctionType;
  scrollAnalytics: ScrollAnalyticsFunctionType;
} {
  const router = useRouter();
  const [pageName, setPageName] = useState('home');

  useEffect(() => {
    const page = Object.entries(pageUrls).find(entity => {
      return router.asPath === entity[1];
    });

    if (page) {
      setPageName(page[0]);
    }
  }, [router.asPath]);

  function clickAnalytics(event: ClickEvent): void {
    pushGmtEvent({
      event: 'a3_click',
      sourcePage: pageName,
      sourceComponent: event.sourceComponent,
      name: event.name,
      SBN: '2',
    });
  }

  function sendFormAnalytics(event: SendFormEvent): void {
    pushGmtEvent({
      event: 'a3_send_form',
      SBN: '3',
      sourceComponent: event.sourceComponent,
      sourcePage: pageName,
      name: event.name,
      result: event.result,
    });
  }

  function scrollAnalytics(event: ScrollEvent): void {
    pushGmtEvent({
      event: 'a3_scroll',
      sourcePage: pageName,
      percent: event.percent.toString(),
      SBN: '1',
    });
  }

  return { clickAnalytics, sendFormAnalytics, scrollAnalytics };
}
