export const sliderClientsSettings = {
  dots: false,
  slidesToShow: 3,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },

    {
      breakpoint: 600,
      settings: {
        centerMode: false,
        slidesToShow: 1,
      },
    },
  ],
};

export const slidesDataClients = [
  // {
  //   text:
  //     'Работая с компанией А3, мы получаем доступ к отраслевой экспертизе и готовым решениям \n' +
  //     'по оптимизации бизнес-процесса платежа, без необходимости тратить время на разработку и тестирование собственных решений.',
  //   img: 'psb.svg',
  //   people: 'Александр Сыроваткин',
  //   role:
  //     'ПАО «Промсвязьбанк»\n' + 'Руководитель по развитию расчетного центра',
  // },
  {
    text: 'В том числе благодаря запуску системы уведомлений совместно с платёжным сервисом А3, наш банк получил 1 место в премии Marksweb в 2021 году.',
    img: 'alfa.svg',
    people: 'Ирина Левина',
    role:
      'АО «АЛЬФА-БАНК»\n' + 'Руководитель направления по развитию переводов',
  },
  {
    text: 'Работаем с компанией А3 более 7 лет. Своевременно предоставляют информацию, наш менеджер и техподдержка оперативно реагируют на запросы. Руководство компании всегда идет навстречу и прислушивается к нашим потребностям.',
    img: 'zhkkh.svg',
    people: 'Андрей Акулов',
    role: 'ООО «МОСОБЛЕИРЦ»\n' + 'Зам. ген. директора по экономике и финансам',
  },
  {
    text: 'А3 оперативно реализовали онлайн протокол, без необходимости внесения изменений. За время сотрудничества зарекомендовали себя как надёжный деловой партнёр, который своевременно и качественно исполняет взятые на себя договорные обязательства.',
    img: 'ufanet.svg',
    people: 'Альбина Хафизова',
    role: 'АО «Уфанет»\n' + 'Руководитель направления Платежных Сервисов',
  },
  {
    text: 'Интегрировались с А3 в 2022 году, полностью устроили скорость и сам процесс интеграции. Наш менеджер всегда был на связи и уточнял все детали, чтобы в будущем не было проблем. Подготовка для внедрения в рабочую среду была проведена на отлично.',
    img: 'ugorski.svg',
    people: 'Андрей Пелинен',
    role:
      'Югорский фонд кап. ремонта многоквартирных домов\n' +
      'Зам. начальника отдела ИТ',
  },
  {
    text: 'С помощью А3 к приёму платежей были оперативно подключены большинство розничных банков, что позволило упростить обработку платежей и повысить клиентский сервис. Клиентский путь реализован индивидуально под каждый банк полностью на стороне А3, с учетом наших требований.',
    img: 'eircspb.svg',
    people: 'Денис Шабарин',
    role: 'АО «ЕИРЦ Санкт-Петербурга»\n' + 'Генеральный директор',
  },
  {
    text: 'Сотрудничаем с А3 более 3 лет, радуют постоянные обновления и улучшения сервиса. Клиентская поддержка на самом высоком уровне.',
    img: 'kvarplata.svg',
    people: 'Кирилл Афонин',
    role: 'ООО «Квартплата 24»\n' + 'Исполнительный директор',
  },
];
