import type { DivProps } from 'app.types';
import cn from 'classnames';
import React from 'react';
import type { FC } from 'react';

import s from './ContentContainer.module.scss';

type Props = {
  grid?: boolean;
} & DivProps;

const ContentContainer: FC<Props> = ({ children, grid, className }) => {
  return (
    <div
      className={cn(s.wrapper, className, {
        grid: grid,
      })}
    >
      {children}
    </div>
  );
};

export default ContentContainer;
