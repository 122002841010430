import Typograf from '@a3/chameleon/src/components/basic/Typograf';

import { Title1 } from 'components/basic/Typography';
import s from 'components/pagesComponents/products/banks/components/NotificationsProtocol/NotificationsProtocol.module.scss';

export const slides = [
  {
    id: 'поиск поставщика',
    content: (
      <Title1 className={s.slideText} Tag="span">
        <Typograf>
          Первый шаг процесса - получить список поставщиков, доступных для
          подписки на уведомления
        </Typograf>
      </Title1>
    ),
    code: {
      request: {
        language: 'bash',
        text: `curl --location --request GET "https://mockapia-3.ru/api/v1/partner/contractor?limit=300&offset=0" --header "Content-Type: application/json" --header "Accept: */*"`,
      },
      response: {
        language: 'json',
        text: `{
    "code": 200,
    "data": [
        {
            "id": 252,
            "name": "ПАО \\"Ростелеком\\"",
            "code": "rostelecom"
        }
    ]
}`,
      },
    },
  },
  {
    id: 'получить идентификатор',
    content: (
      <Title1 className={s.slideText} Tag="span">
        <Typograf>
          Второй шаг - получить список возможных идентификаторов для подписки
          (для отображения пользователю)
        </Typograf>
      </Title1>
    ),
    code: {
      request: {
        language: 'bash',
        text: `curl --location --request GET "https://mockapia-3.ru/api/v1/partner/contractor/subscription-identities?id=252" --header "Content-Type: application/json" --header "Accept: */*"`,
      },
      response: {
        language: 'json',
        text: `{
    "title": "Лицевой счет у конечного Поставщика услуг",
    "subscriptionIdentities": [
      {
        "identity": "SUPPLIER_ACCOUNT_NUMBER",
        "required": true,
        "value": null,
        "readonly": false
      }
    ]
  }`,
      },
    },
  },
  {
    id: 'подписаться',
    content: (
      <Title1 className={s.slideText} Tag="span">
        <Typograf>
          Третий шаг – подписаться на уведомления от поставщика
        </Typograf>
      </Title1>
    ),
    code: {
      request: {
        language: 'bash',
        text: `curl --location --request POST
  "https://mockapia-3.ru/api/v1/partner/subscription" \\
  --header "accept: application/json;charset=UTF-8" \\
  --header "Content-Type: application/json"
  --data-raw "{
      "identities": {
          "SUPPLIER_ACCOUNT_NUMBER": "75554000023"
      },
      "contractor": 252,
      "client": {
          "partnerClientId": "20020517"
      }
  }"`,
      },
      response: {
        language: 'json',
        text: `{
    "code": 200,
    "data": {
      "id": 904867,
      "created": "2022-03-17 01:11:06"
    }
  }`,
      },
    },
  },
  {
    id: 'получать уведомления',
    content: (
      <Title1 className={s.slideText} Tag="span">
        <Typograf>
          Четвёртый шаг – получать уведомления одним из следующих способов:{' '}
          <br />
          <br />
          1. Периодически опрашивать А3 по новым событиям <br />
          <br />
          2. Опубликовать сервис, в который А3 будет отправлять уведомления
        </Typograf>
      </Title1>
    ),
    code: {
      request: {
        language: 'bash',
        text: `curl --location --request GET
  "http://oapi.a-3.ru/api/v1/partner/client-notification?limit=100&offset=0&contractorId=252&direction=desc&sortColumn=triggerDate" \\
  --header "Content-Type: application/json" \\
  --header "Accept: */*"`,
      },
      response: {
        language: 'json',
        text: `{
    "triggerDate": "2022-03-21 12:33:49",
    "client": {
      "id": 868845,
      "partnerClientId": "20020526"
    },
    "event": {
      "title": "Все новые начисления РТК",
      "code": "all_new_rtk_invoices",
      "eventType": "NEW",
      "id": 46
    },
    "contractor": {
      "id": 252,
      "name": "ПАО \\"Ростелеком\\"",
      "code": "rostelecom"
    },
    "message": "{\\"accountNumber\\":\\"75554000028\\",  \\"invoiceId\\":\\"54485\\",  \\"invoiceNumber\\":\\"54485\\"  \\"invoiceType\\":\\"COMMUNICATION\\", \\"invoiceStatus\\":\\"NOT_PAID\\", \\"invoiceCreationDate\\":\\"2022-03-21 12:33:48\\", \\"invoiceBeginDate\\":\\"2022-03-01 00:00:00\\", \\"invoiceEndDate\\":\\"2022-03-31 23:59:59\\", \\"invoiceExpiryDate\\":\\"2022-03-31 23:59:59\\", \\"invoiceSum\\":\\"25700\\", \\"smsType\\":\\"0\\",  \\"invoiceComments\\": \\"\\"  }"
  }
  `,
      },
    },
  },
];
