import React from 'react';
import type { FC } from 'react';

import { Caption2 } from 'components/basic/Typography';
import s from 'components/complex/NavigationMenu/MenuLabel/MenuLabel.module.scss';
import type { ItemsTypes } from 'components/complex/NavigationMenu/types';

const MenuLabel: FC<ItemsTypes> = ({ text }) => {
  return <Caption2 className={s.label}>{text}</Caption2>;
};

export default MenuLabel;
