import type { DetailedHTMLProps, HTMLAttributes } from 'react';

export enum ColorsEnum {
  purple = 'purple',
  peach = 'peach',
  marine = 'marine',
  blue = 'blue',
  lilac = 'lilac',
}

export type DivProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export interface PublicRuntimeConfig {
  app: {
    version: string;
    env: {
      raw: string;
      isDev: boolean;
      isProd: boolean;
      testMockMode: boolean;
    };
  };
  host: string;
  fapiUrl: string;
  analytics: {
    enable?: boolean;
    gtmId: string;
  };
  sentry: {
    dsn: string;
    environment: string;
  };
  environmentName: 'prod' | 'stage' | 'develop';
}
