import cn from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';
import type { FC } from 'react';

import ALink from 'components/basic/ALink';
import s from 'components/complex/NavigationMenu/MenuItem/MenuItem.module.scss';
import type { MenuItemTypes } from 'components/complex/NavigationMenu/types';

const MenuItem: FC<MenuItemTypes> = ({
  href,
  text,
  color = 'purple',
  onClick,
}) => {
  const router = useRouter();

  return (
    <ALink
      className={cn(s.wrapper, {
        [s.current]: href?.includes(router.asPath) && router.asPath !== '/',
      })}
      href={href}
      onClick={onClick}
    >
      <div className={cn(s.bubble, s[`bubble-${color}`])}>a3</div>
      <li className={cn(s.text, s[`text-${color}`])}>{text}</li>
    </ALink>
  );
};

export default MenuItem;
