import cn from 'classnames';
import hljs from 'highlight.js/lib/core';
import bash from 'highlight.js/lib/languages/bash';
import json from 'highlight.js/lib/languages/json';
import xml from 'highlight.js/lib/languages/xml';
import React, { useEffect, useState } from 'react';
import type { FC, RefObject } from 'react';
import { useInterval } from 'usehooks-ts';

import s from './CodeTyping.module.scss';

hljs.registerLanguage('xml', xml);
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('json', json);

interface SimpleTapingProps {
  text: string;
  language: string;
  id: string;
  onTypingDone: (id: string) => void;
  currentTyping: string | null;
  wrapperRef: RefObject<HTMLDivElement>;
}

const SimpleTaping: FC<SimpleTapingProps> = ({
  text = '',
  language = 'xml',
  id,
  onTypingDone,
  currentTyping,
  wrapperRef,
}) => {
  const [textByLetters] = useState(text.split(''));
  const [letters, setLetters] = useState('');
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0);
  const [delay, setDelay] = useState<number | null>(null);
  const [linesCount, setLinesCount] = useState(1);

  useEffect(() => {
    if (currentTyping === id) {
      setDelay(20);
    }

    if (currentTyping === null) {
      setLetters('');
      setLinesCount(0);
      setCurrentLetterIndex(0);
      setDelay(null);
    }
  }, [id, currentTyping]);

  function updateLetter(): void {
    if (currentLetterIndex < textByLetters.length) {
      const newLetters = letters + textByLetters[currentLetterIndex];
      //считаем, что текст явно разделен на новые строки через enter на клаве
      const newLinesCount = newLetters.match(/\n/g)?.length;

      if (newLinesCount) {
        setLinesCount(newLinesCount);

        if (newLinesCount > linesCount) {
          if (wrapperRef.current) {
            wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
          }
        }
      }
      setLetters(newLetters);
      setCurrentLetterIndex(currentLetterIndex + 1);
    } else {
      setDelay(null);
      onTypingDone(id);
    }
  }

  useInterval(updateLetter, delay);

  const linesNumbers = [];

  for (let i = 0; i <= linesCount; i++) {
    linesNumbers.push(<div key={i}>{i + 1}</div>);
  }

  return (
    <>
      <div className={s.linesNumberWrapper}>{linesNumbers}</div>
      <div
        className={cn(s.wrapper, {
          [s.wrap]: language === 'bash',
        })}
        dangerouslySetInnerHTML={{
          __html: hljs.highlight(letters, { language }).value,
        }}
      />
    </>
  );
};

export default SimpleTaping;
