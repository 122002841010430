import cn from 'classnames';
import type { HTMLMaskElement } from 'imask';
import type { ChangeEvent } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IMaskInput } from 'react-imask';

import Icon from 'components/basic/Icon';
import { IconTypes } from 'components/basic/Icon/types';

import s from './InputText.module.scss';

export interface InputTextProps<ObjectShape> {
  name: keyof ObjectShape;
  requiredSymbolLabel?: boolean;
  required?: boolean;
  labelText?: string | JSX.Element;
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  errorMessage?: string;
  type?: 'text' | 'textarea' | 'password' | 'mask' | 'number';
  smallSize?: boolean;
  doValidate?: (field: keyof ObjectShape, value: string) => Promise<void>;
  autocomplete?: string;
  placeholder?: string;
  helpText?: string;
  withLengthCounter?: boolean;
  maxLength?: number;
  isDisabled?: boolean;
  buttonText?: string;
  buttonHandle?: () => void;
  mask?: string;
  isShowErrorOnBlur?: boolean;
}

interface MaskedInputRefTypes {
  maskRef: { el: HTMLMaskElement };
}

// eslint-disable-next-line complexity
export default function InputText<ObjectShape>(
  props: InputTextProps<ObjectShape>,
): JSX.Element {
  const {
    value,
    onChange: onChangeProp,
    name,
    labelText,
    errorMessage: _errorMessage,
    type = 'text',
    doValidate,
    autocomplete = 'off',
    placeholder,
    maxLength = 0,
    withLengthCounter,
    isDisabled,
    className,
    mask,
    required,
    isShowErrorOnBlur,
  } = props;
  const [isPasswordShowed, setPasswordShowed] = useState(false);
  const ref = useRef(null);
  const [errorMessage, setErrorMessage] = useState(_errorMessage);
  const maskedRef = useRef<MaskedInputRefTypes>(null);

  useEffect(() => {
    let inputRef: HTMLInputElement | HTMLElement | null | undefined =
      ref.current;

    if (type === 'mask') {
      inputRef = maskedRef.current?.maskRef.el.input;
    }

    //если нужно показывать ошибку только при блуре, то затираем текущую ошибку
    if (
      _errorMessage &&
      isShowErrorOnBlur &&
      document.activeElement === inputRef
    ) {
      setErrorMessage(undefined);
    } else {
      setErrorMessage(_errorMessage);
    }
  }, [type, isShowErrorOnBlur, _errorMessage, value]);

  async function onChange(
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | { target: { value: string } },
  ): Promise<void> {
    if (onChangeProp) {
      onChangeProp(e.target.value);
    }

    if (doValidate && e.target.value !== '') {
      await doValidate(name, e.target.value);
    }
  }

  const onBlur = useCallback(async () => {
    if (isShowErrorOnBlur) {
      //если нужно показывать ошибку только блуре, восстанавливаем стёртую ошибку
      setErrorMessage(_errorMessage);
    }

    if (doValidate && typeof value === 'string') {
      await doValidate(name, value);
    }
  }, [isShowErrorOnBlur, doValidate, value, _errorMessage, name]);
  const hasErrorMessage = errorMessage !== undefined && errorMessage.length > 0;
  const hasPlaceholder = placeholder !== undefined && placeholder.length > 0;

  let input = <div />;

  let valueLength = 0;

  if (value) {
    valueLength = value.toString().length;
  }

  const defaultProps = {
    id: name as string,
    value,
    disabled: isDisabled,
    onChange,
    onBlur,
    autoComplete: autocomplete,
    placeholder,
    required,
    ref,
  };

  if (type === 'mask' && typeof mask !== 'undefined') {
    if (typeof defaultProps.placeholder === 'undefined') {
      defaultProps.placeholder = mask;
    }

    input = (
      <IMaskInput
        value={value}
        disabled={isDisabled}
        onAccept={(val): void => {
          // Пришлось делать такой костыль из-за странной логике пакета.
          // Функция onChange срабатывает не тогда, когда должна
          // https://github.com/uNmAnNeR/imaskjs/issues/318
          onChange({ target: { value: val as string } });
        }}
        onBlur={onBlur}
        mask={mask}
        ref={maskedRef}
        autoComplete={autocomplete}
        placeholder={placeholder}
        className={cn(s.input, {
          [s.withErrorBorder]: hasErrorMessage,
        })}
      />
    );
  }

  if (type === 'text' || type === 'number') {
    input = (
      <input
        {...defaultProps}
        className={cn(s.input, {
          [s.withErrorBorder]: hasErrorMessage,
        })}
      />
    );
  }

  if (type === 'password') {
    input = (
      <input
        id={name as string}
        value={value}
        disabled={isDisabled}
        onChange={onChange}
        onBlur={onBlur}
        type={isPasswordShowed ? 'text' : 'password'}
        autoComplete={autocomplete}
        className={cn(s.input, s.withIcon, {
          [s.withErrorBorder]: hasErrorMessage,
        })}
      />
    );
  }

  if (type === 'textarea') {
    input = (
      <textarea
        {...defaultProps}
        className={cn(s.input, s.textAriaSize, {
          [s.withErrorBorder]: hasErrorMessage,
        })}
      />
    );
  }

  return (
    <div className={className}>
      <label
        htmlFor={name as string}
        className={cn(s.label, {
          [s.labelWithError]: errorMessage,
        })}
      >
        {labelText && (
          <>
            {input}
            <div
              className={cn(s.floatLabel, {
                [s.withError]: hasErrorMessage,
                [s.activeLabel]: hasPlaceholder,
                [s.hasValue]: !!value,
              })}
            >
              {labelText}
            </div>
          </>
        )}

        {type === 'password' && (
          <div
            className={s.passwordEye}
            onClick={(): void => setPasswordShowed(current => !current)}
          >
            <Icon
              icon={isPasswordShowed ? IconTypes.eye : IconTypes.eyeClosed}
              size={24}
              color="#1a87f5"
            />
          </div>
        )}
      </label>
      {(withLengthCounter || hasErrorMessage) && (
        <div className={cn(s.wrapperInfo)}>
          {hasErrorMessage && (
            <div
              className={cn(s.helpText, {
                [s.withError]: hasErrorMessage,
              })}
            >
              {errorMessage}
            </div>
          )}

          {withLengthCounter && (
            <div
              className={cn(s.lengthCounter, {
                [s.withError]: hasErrorMessage,
              })}
            >
              {valueLength}/{maxLength}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
