import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import { ColorsEnum } from 'app.types';
import React from 'react';
import type { FC } from 'react';

import ContentContainer from 'components/basic/ContentContainer';
import { Body4, H2, Title2 } from 'components/basic/Typography';
import CodeSlider from 'components/complex/CodeSlider';
import NumericIndicator from 'components/pagesComponents/main/components/SimplifyProcesses/components/NumericIndicator';
import { numericData } from 'components/pagesComponents/main/components/SimplifyProcesses/components/NumericIndicator/data';
import { slides } from 'components/pagesComponents/products/banks/components/NotificationsProtocol/constants';

import s from './SimplifyProcesses.module.scss';

const SimplifyProcesses: FC = () => {
  const numerics = numericData.map(
    ({ separator, numbers, unit, description }) => {
      return (
        <NumericIndicator
          key={description}
          separator={separator}
          numbers={numbers}
          unit={unit}
          description={description}
        />
      );
    },
  );

  return (
    <Typograf>
      <div className={s.wrapper}>
        <div className={s.videoWrapper}>
          <video
            autoPlay
            loop
            playsInline
            muted
            preload="auto"
            src="/componentsImages/EnginePage/HeroEngine/hero.mp4"
            className={s.video}
          />
          <H2 className={s.h2}>
            Упрощаем сложные
            <br />
            процессы
          </H2>
          <Body4 className={s.body}>
            Создаем технологические продукты, которые обеспечивают
            функциональное взаимодействие между всеми участниками
            бизнес-процесса платежа, сохраняя логику процесса на своей стороне
          </Body4>
        </div>

        <ContentContainer>
          <div className={s.numbersWrapper}>{numerics}</div>
          <Title2 className={s.titleExpert}>
            Интеграционная экспертиза
            <br />c 2010 года
          </Title2>
          <CodeSlider
            slides={slides}
            className={s.codeSlider}
            color={ColorsEnum.blue}
          />
        </ContentContainer>
      </div>
    </Typograf>
  );
};

export default SimplifyProcesses;
