export const numericData = [
  {
    separator: '>',
    numbers: '950',
    unit: '',
    description: 'партнеров',
  },
  {
    separator: '>',
    numbers: '60',
    unit: 'тыс',
    description: 'база поставщиков услуг',
  },
  {
    separator: '>',
    numbers: '122',
    unit: 'млн',
    description: 'актуальных лицевых счетов с начислениями',
  },
  {
    separator: ' ',
    numbers: '80',
    unit: '',
    description: 'регионов присутствия по ЖКХ',
  },
];
