export interface DataLayerObj {
  event: string;
  sourcePage: string;
  sourceComponent?: string;
  SBN: string;
  name?: string;
  result?: string;
  percent?: string;
}

export default function pushGmtEvent(data: DataLayerObj): void {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
}
