import localFont from '@next/font/local';
import { analyticsScript } from 'analytics/AnalyticScript';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import React from 'react';

import ScrollAnalytics from 'components/basic/ScrollAnalytics';

import 'styles/globals.scss';

const mont = localFont({
  display: 'swap',
  src: [
    {
      path: '../styles/fonts/Mont/Mont-Regular.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../styles/fonts/Mont/Mont-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../styles/fonts/Mont/Mont-SemiBold.woff',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../styles/fonts/Mont/Mont-SemiBold.woff2',
      weight: '500',
      style: 'normal',
    },
  ],
});

function App({
  Component,
  pageProps,
  err,
}: AppProps & { err: Record<string, string> }): JSX.Element {
  return (
    <main className={mont.className}>
      <ScrollAnalytics>
        <Script
          strategy="afterInteractive"
          src="https://www.google.com/recaptcha/api.js?render=6LesiMIgAAAAACOqcL-eRNY3xLpGztchfESHHt9k"
        />
        <Script id="AnalyticsScriptFunc.script" strategy="beforeInteractive">
          {analyticsScript}
        </Script>
        <Component {...pageProps} err={err} />
      </ScrollAnalytics>
    </main>
  );
}

export default App;
