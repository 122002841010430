export const colorsVariables = {
  purple: {
    '--background': 'var(--purple-gradient-5)',
  },
  peach: {
    '--background': 'var(--peach-gradient-5)',
  },
  marine: {
    '--background': 'var(--marine-gradient-5)',
  },
  blue: {
    '--background': 'var(--dark-blue-background)',
  },
  lilac: {
    '--background': 'var(--lilac-gradient-5)',
  },
};
