export const defaultTitle = 'Платёжный сервис А3';
export const defaultDescription =
  'Оплата коммунальных услуг, штрафов ГИБДД, мобильной связи, Интернета, налогов, учебных заведений и многих других услуг онлайн.';
export const defaultOgDescription =
  'Оплата коммунальных услуг, штрафов ГИБДД, мобильной связи, Интернета, налогов, учебных заведений и многих других услуг онлайн.';

export const jsonLd = {
  '@context': 'http://schema.org',
  '@type': 'Corporation',
  name: 'Платёжный сервис А3',
  description:
    'Оплата коммунальных услуг, штрафов ГИБДД, мобильной связи, Интернета, налогов, учебных заведений и многих других услуг онлайн.',
  logo: 'https://www.a-3.ru/favicons/android-chrome-512x512.png',
  url: 'https://www.a-3.ru/',
  email: 'mailto:',
  sameAs: [],
  address: {
    //TODO
    // '@type': 'PostalAddress',
    // streetAddress: '541 Jefferson Avenue',
    // addressLocality: 'Redwood City',
    // addressRegion: 'CA',
    // postalCode: '94063',
    // addressCountry: 'United States',
  },
};
