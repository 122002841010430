import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import React from 'react';
import type { FC } from 'react';

import ContentContainer from 'components/basic/ContentContainer';
import { Body4, H2 } from 'components/basic/Typography';
import NavigationMenu from 'components/complex/NavigationMenu';
import s from 'components/pagesComponents/main/components/HeroMain/HeroMain.module.scss';

interface HeroMainTypes {
  setShowForm: (value: boolean) => void;
}

const HeroMain: FC<HeroMainTypes> = ({ setShowForm }) => {
  return (
    <>
      <NavigationMenu setShowForm={setShowForm} />
      <video
        autoPlay
        loop
        playsInline
        muted
        preload="auto"
        src="/componentsImages/MainPage/HeroMain/background.mp4"
        poster="/componentsImages/MainPage/HeroMain/poster.jpg"
        className={s.video}
      />
      <ContentContainer className={s.wrapper}>
        <Typograf>
          <H2 className={s.h2}>
            <span className="text-gradient-blue">Быстрые и удобные</span>
            <br />
            платежи
          </H2>
          <Body4 className={s.body}>
            Мы создаем технологические продукты для оптимизации регулярных
            платежей, делая оплату услуг в привычных каналах простой и удобной
            для пользователя
          </Body4>
        </Typograf>
      </ContentContainer>
    </>
  );
};

export default HeroMain;
