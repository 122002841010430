import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import cn from 'classnames';
import React from 'react';
import type { FC } from 'react';

import ContentContainer from 'components/basic/ContentContainer';
import { Body4, H2, Title2 } from 'components/basic/Typography';

import s from './WhyAreWe.module.scss';

const WhyAreWe: FC = () => {
  return (
    <Typograf>
      <div className={s.wrapper}>
        <ContentContainer grid>
          <H2 className={cn(s.head, 'g-col-m-12 g-col-xss-8')}>Почему мы:</H2>
          <img
            className={cn(
              s.developImage,
              'g-start-m-2 g-start-xss-1 g-col-m-4 g-col-s-3 g-col-xss-8',
            )}
            src="/componentsImages/MainPage/WhyAreWe/developMarket.jpg"
            alt="develop the market"
          />
          <Title2
            className={cn(
              s.developTitle,
              'g-start-m-8 g-start-s-5 g-start-xss-1 g-col-3xl-4 g-col-m-3 g-col-s-4 g-col-xss-8',
            )}
          >
            Развиваем рынок электронных платежей
          </Title2>
          <Body4
            className={cn(
              s.descriptionDevelop,
              'g-start-m-9 g-start-s-5 g-start-xss-1 g-col-m-3 g-col-s-4 g-col-xss-8',
            )}
          >
            А3 является участником инновационного проекта «Сколково»,
            осуществляющим исследовательскую деятельность, способствующую
            достижению цифровой зрелости ключевых отраслей экономики и
            социальной сферы России и увеличению доли массовых социально
            значимых услуг.
          </Body4>
          <Title2
            className={cn(
              s.skTitle,
              'g-start-m-2 g-start-s-5 g-start-xss-1 g-col-3xl-5 g-col-s-4 g-col-xss-8',
            )}
          >
            Используем последние технологии
          </Title2>
          <Body4
            className={cn(
              s.skDescription,
              'g-start-m-3 g-start-s-5 g-start-xss-1 g-col-m-3 g-col-s-4 g-col-xss-8',
            )}
          >
            Мы используем актуальный стек технологий, позволяющий развиваться
            компании и сотрудникам в соответствии с запросами и трендами
            индустрии.
          </Body4>
          <Title2
            className={cn(
              s.productsTitle,
              'g-start-m-2 g-start-xss-1 g-col-3xl-5 g-col-s-4 g-col-xss-8',
            )}
          >
            Придумываем и развиваем новые продукты для отрасли
          </Title2>
          <Body4
            className={cn(
              s.productsDescription,
              'g-start-m-3 g-start-xss-1 g-col-m-3 g-col-s-4 g-col-xss-8',
            )}
          >
            Собрав в своей команде лучших специалистов отрасли финтеха, каждый
            год мы выпускаем сотни функций и улучшений, чтобы помочь вам
            опережать изменения в отрасли.
          </Body4>
          <img
            className={cn(
              s.productsImg,
              'g-start-m-7 g-start-s-5 g-start-xss-1 g-col-m-5 g-col-s-4 g-col-xss-8',
            )}
            src="/componentsImages/MainPage/WhyAreWe/newProducts.jpg"
            alt="new products photo"
          />
          <img
            className={cn(
              s.serviceImg,
              'g-start-m-2 g-start-xss-1 g-col-m-5 g-col-s-3 g-col-xss-8',
            )}
            src="/componentsImages/MainPage/WhyAreWe/makeService.jpg"
            alt="make service image"
          />
          <Title2
            className={cn(
              s.serviceTitle,
              'g-start-m-8 g-start-s-5 g-start-xss-1 g-col-s-4 g-col-xss-8',
            )}
          >
            Оказываем сервис с индивидуальным подходом
          </Title2>
          <Body4
            className={cn(
              s.serviceDescription,
              'g-start-m-9 g-start-s-5 g-start-xss-1 g-col-m-3 g-col-s-4 g-col-xss-8',
            )}
          >
            Каждому контрагенту предоставляется личный менеджер,
            а конструирование процесса платежа происходит с учетом потребностей
            и специфики работы партнера.
          </Body4>
        </ContentContainer>
      </div>
    </Typograf>
  );
};

export default WhyAreWe;
