import { motion, useAnimation } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC, DetailedHTMLProps, HTMLAttributes } from 'react';
import { useInViewport } from 'react-in-viewport';

import s from './Ticker.module.scss';

interface TickerProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  content: JSX.Element | string;
  speedInSecond?: number;
}

const Ticker: FC<TickerProps> = ({
  content,
  speedInSecond = 20,
  className,
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [clonedContent, setClonedContent] = useState<(JSX.Element | string)[]>(
    [],
  );
  const myRef = useRef<HTMLDivElement>(null);
  const { inViewport } = useInViewport(myRef);
  const controls = useAnimation();

  useEffect(() => {
    if (isLoaded && inViewport) {
      controls.start({
        x: [0, -scrollWidth],
        transition: {
          x: {
            repeat: Infinity,
            repeatType: 'loop',
            duration: speedInSecond,
            ease: 'linear',
          },
        },
      });
    }

    if (!inViewport) {
      controls.stop();
    }
  }, [scrollWidth, speedInSecond, isLoaded, controls, inViewport]);

  const initTicker = useCallback(() => {
    if (!ref.current) return;

    const windowWidth = window.innerWidth;
    let refWidth = ref.current.offsetWidth;
    const contentToRender = [];

    if (refWidth === 0) {
      refWidth = 1; //потому что делить на 0 нельзя
    }

    if (refWidth > windowWidth) {
      contentToRender.push(content);
      contentToRender.push(content);
    } else {
      const ratio = Math.ceil(windowWidth / refWidth);

      for (let i = 0; i < ratio * 2; i++) {
        contentToRender.push(content);
      }
    }
    setClonedContent(contentToRender);
    setScrollWidth(refWidth);
    setIsLoaded(true);
  }, [content]);

  useEffect(() => {
    if (ref.current && !isLoaded) {
      //из-за подгрузки шрифта высчитывается неверная ширина контейнера. Подумать, как решить эту проблему и стартовать после того, как загрузились ресурсы
      setTimeout(initTicker, 1000);
    }
  }, [initTicker, ref, isLoaded, content]);

  return (
    <div className={className} ref={myRef}>
      <div className={s.ticker}>
        <motion.div className={s.track} animate={controls}>
          <span ref={ref}>{isLoaded ? clonedContent : content}</span>
        </motion.div>
      </div>
    </div>
  );
};

export default Ticker;
