import type { DivProps } from 'app.types';
import cn from 'classnames';
import type { ReactNode } from 'react';
import React, { useCallback, useRef } from 'react';
import type { Settings } from 'react-slick';
import SlickCarousel from 'react-slick';

import s from './Carousel.module.scss';

interface CarouselProps extends DivProps {
  settings?: Settings;
  children?: ReactNode;
  customArrows?: (
    nextSlide?: () => void,
    previousSlide?: () => void,
  ) => JSX.Element;

  customDots?: (slickGoTo: (slideNumber: number) => void) => JSX.Element;
}

const Carousel: React.FC<CarouselProps> = props => {
  const {
    className,
    children,
    settings = {},
    customArrows,
    customDots,
  } = props;
  const sliderRef = useRef<SlickCarousel>(null);
  const settingsSlider = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    ...settings,
  };
  const slickPrev = useCallback(() => {
    if (sliderRef.current) {
      return sliderRef.current.slickPrev();
    }
  }, []);
  const slickNext = useCallback(() => {
    if (sliderRef.current) {
      return sliderRef.current.slickNext();
    }
  }, []);
  const slickGoTo = useCallback((slideNumber: number) => {
    if (sliderRef.current) {
      return sliderRef.current.slickGoTo(slideNumber);
    }
  }, []);

  return (
    <div className={cn(s.root, className)}>
      {customDots?.(slickGoTo)}
      <SlickCarousel ref={sliderRef} {...settingsSlider}>
        {children}
      </SlickCarousel>
      {customArrows?.(slickPrev, slickNext)}
    </div>
  );
};

export default Carousel;
