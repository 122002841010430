import type { ColorsEnum } from 'app.types';
import type { ReactNode } from 'react';

export interface PageProps {
  children: ReactNode;
  fullHeight?: boolean;
  fullWidth?: boolean;
  withoutHeader?: boolean;
  withoutFooter?: boolean;
  className?: string;
  grid?: boolean;
  removeBottomPadding?: boolean;
  color?: ColorsEnum;
  isLightFooter?: boolean;
  seo?: {
    title?: string;
    description?: string;
    themeColor?: ThemeColorMeta;
  };
}

export enum ThemeColorMeta {
  /**  --text-white: #ffffff; **/
  DefaultWhite = '#ffffff',
  /** --dark-blue-background: #070c2b; **/
  DarkBlue = '#070c2b',
  /** --light-purple-100: #948de5; градиент нельзя **/
  LightPurple = '#948de5',
  /** --light-lilac-100: #cf87dd; - не подошёл, поэтому взял в фигме пипеткой **/
  LightLilac = '#CF7DDC',
  /** --light-marine-100: #54becc; **/
  LightMarine = '#54becc',
  /** --light-blue-100: #3489f3; - не подошёл, взял с градиента второй тон и развернул градиент сверху вниз **/
  LightBlue = '#1b6fe7',
}
