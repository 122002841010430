import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import { ColorsEnum } from 'app.types';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import React from 'react';
import type { FC } from 'react';

import { gtmEvents } from 'constants/gtmEvent';

import ALink from 'components/basic/ALink';
import ContentContainer from 'components/basic/ContentContainer';
import ProductCard from 'components/basic/ProductCard';
import { Body4, H3 } from 'components/basic/Typography';
import AppleStore from 'components/pagesComponents/main/components/BusinessSolutions/svg/apple.svg';
import GoogleStore from 'components/pagesComponents/main/components/BusinessSolutions/svg/google.svg';
import Rustore from 'components/pagesComponents/main/components/BusinessSolutions/svg/rustore.svg';

import s from './IndividualService.module.scss';

const IndividualService: FC = () => {
  const { clickAnalytics } = useAnalytics();

  return (
    <Typograf>
      <div className={s.wrapper}>
        <ContentContainer grid>
          <div className={cn(s.info, 'g-col-m-6 g-col-xss-8')}>
            <H3 className={s.h3}>
              Сервис а3 <br />
              <span className="text-gradient-blue">для физлиц</span>
            </H3>
            <Body4 className={s.body4}>
              Удобные сервисы для оплаты всех регулярных начислений в формате
              одного окна
            </Body4>
            <div className={s.stores}>
              <ALink
                className={s.store}
                href="https://www.a-3.ru/ios"
                target="_blank"
              >
                <AppleStore
                  onClick={(): void => clickAnalytics(gtmEvents.clickAppStore)}
                />
              </ALink>
              <ALink
                className={s.store}
                href="https://www.a-3.ru/android"
                target="_blank"
              >
                <GoogleStore
                  onClick={(): void =>
                    clickAnalytics(gtmEvents.clickGoogleStore)
                  }
                />
              </ALink>
              <ALink
                className={s.store}
                href="https://apps.rustore.ru/app/ru.a3.lk"
                target="_blank"
              >
                <Rustore
                  onClick={(): void => clickAnalytics(gtmEvents.clickRuStore)}
                />
              </ALink>
            </div>
          </div>
          <ProductCard
            color={ColorsEnum.blue}
            className={cn(
              s.product,
              'g-col-m-6 g-col-s-4 g-col-xss-8 g-start-s-3 g-start-m-7',
            )}
          />
        </ContentContainer>
      </div>
    </Typograf>
  );
};

export default IndividualService;
