import cn from 'classnames';
import React from 'react';
import type { FC } from 'react';
import type { Props as OriginalProps } from 'react-select';

import SelectBasic from 'components/basic/Select';
import type { SelectOnChangeType } from 'components/basic/Select/types';
import type { SelectOptionType } from 'components/complex/forms/ApplicationForm/types';

import DropdownIndicator from './dropdownIndicator.svg';

import s from './SelectBasicStylized.module.scss';

interface SelectBasicStylizedTypes extends OriginalProps {
  labelText?: string;
  placeholder?: string;
  errorMessage?: string;
  value: SelectOptionType | undefined;
  onChange?: SelectOnChangeType;
  noOptionsMessage?:
    | ((obj: { inputValue: string }) => string | null)
    | undefined;
}

const SelectBasicStylized: FC<SelectBasicStylizedTypes> = ({
  labelText,
  value,
  placeholder,
  errorMessage,
  onChange,
  className,
  options,
}) => {
  return (
    <div className={cn(s.root, className)}>
      <SelectBasic
        value={value}
        labelText={labelText}
        placeholder={placeholder}
        errorMessage={errorMessage}
        noOptionsMessage={(): string => 'Нет вариантов'}
        onChange={onChange}
        options={options}
        externalComponents={{
          DropdownIndicator: () => <DropdownIndicator />,
        }}
      />
    </div>
  );
};

export default SelectBasicStylized;
